import React from "react";
import styled, { keyframes } from "styled-components";
import init from "./Editor";
import { FlowsPanel } from "./flows/Flows";
import {Phone, PreviewView} from "./preview/Preview";
import { CodeEditorView } from "./console/Console";
import { InspectorPanel } from "./inspector/Inspector";
import { HeaderView, EditorMode } from "./header/Header";
import { ProgressIndicator } from "./views/ProgressIndicator/ProgressIndicator";
import ResizePanel from "react-resize-panel";
import {DEFAULT_FLOW} from "./Constants";
import "./styles.css";

export let loader: ProgressIndicator;
export let inspector: InspectorPanel;
export let flows: FlowsPanel;
export let header: HeaderView;
export let diagram: HTMLDivElement;
export let iphoneView: PreviewView;
export let androidView: PreviewView;
export let codeView: CodeEditorView;
export let code: HTMLDivElement;
export let iphone: HTMLDivElement;
export let android: HTMLDivElement;

const rotateKeyframes = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Styled = {
    App: styled.div`
        margin: 0;
        padding: 0;
        position: absolute;
        width: 100vw;
        height: 100vh;
        bottom: 0;
        left: 0;
        z-index: 0;
        overflow: hidden;
    `,
    EditorContainer: styled.div`
        background-image: url("./tile.png");
        background-repeat: repeat;
        background-size: 30px 30px;
        background-color: #fbfbfb;
        width: 100vw;
        height: calc(100vh - 71px);
        margin: 0;
        padding: 0;
        position: absolute;
        top: 71px;
        left: 0px;
        overflow: auto;
        opacity: 1;
        transition: all 0.3s cubic-bezier(0.05, 0.03, 0.35, 1);
    `,
    Preview: styled.div`
        display: block;
        background-color: white;
        width: 100vw;
        height: calc(100vh - 71px);
        margin: 0;
        padding: 0;
        position: absolute;
        top: 71px;
        left: 0px;
        overflow: auto;
        transition: all 0.3s;
    `,
    FloatingPreview: styled.div`
        display: block;
        background: transparent;
        background-color: transparent;
    `,
    CodeEditor: styled.div`
        background-color: #444444;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-flow: column nowrap;
        flex-grow: 1;
        position: absolute;
        padding: 0 6px;
        margin: 0;
        left: 10px;
        bottom: 0;
        width: 100vw;
        height: auto;
        min-height: 10px;
        overflow-y: scroll;
    `,
    ResizableConsole: styled(ResizePanel)``,
    Component: styled.div``,
    Spinner: styled.div`
        display: inline-block;
        animation: ${rotateKeyframes} 2s linear infinite;
        padding: 2rem 1rem;
        font-size: 1.2rem;
    `,
    MenuBar: styled.div`
        margin-top: 100px;
        text-align: center;
    `
};

export var userName: string = "";

export async function setUser(user: string) {
    localStorage.setItem("user", user);
    userName = user;
}

export function getStoredUser() {
    const key = "user";

    if (localStorage.hasOwnProperty(key)) {
        let value = localStorage.getItem(key);

        try {
            if (typeof value === "string") userName = JSON.parse(value) || "";
        } catch (e) {
            userName = "";
        }
    }

    userName = localStorage.getItem("user") || "";
}

class App extends React.Component {
    render() {
        return (
            <Styled.App>
                <HeaderView
                    ref={(el: HeaderView) => (header = el)}
                    mode={EditorMode.BOTH}
                    name={"Loading..."}
                    description={Date.now()}
                />
                <FlowsPanel
                    ref={(el: FlowsPanel) => (flows = el)}
                    isVisible={false}
                    isInputVisible={false}
                    items={[]}
                />
                <InspectorPanel
                    ref={(el: InspectorPanel) => (inspector = el)}
                    key={"inspector-panel"}
                />
                <Styled.EditorContainer ref={(el: HTMLDivElement) => (diagram = el)}>
                    <Styled.Component ref={(el: HTMLDivElement) => init(el)}/>
                </Styled.EditorContainer>
                <Styled.CodeEditor ref={(el: HTMLDivElement) => (code = el)}>
                    <Styled.ResizableConsole direction={"n"}>
                        <CodeEditorView
                            ref={(el: CodeEditorView) => (codeView = el)}
                            isVisible={true}
                            code={"// JSON appears here"}
                        />
                    </Styled.ResizableConsole>
                </Styled.CodeEditor>
                <Styled.FloatingPreview ref={(el: HTMLDivElement) => (iphone = el)}>
                    <PreviewView
                        ref={(el: PreviewView) => (iphoneView = el)}
                        isVisible={false}
                        deviceColor={"black"}
                        deviceType={Phone.IPHONE}
                        scale={65}
                        flowId={DEFAULT_FLOW}
                        isDualMode={false}
                    />
                </Styled.FloatingPreview>
                <Styled.FloatingPreview ref={(el: HTMLDivElement) => (android = el)}>
                    <PreviewView
                        ref={(el: PreviewView) => (androidView = el)}
                        isVisible={false}
                        deviceColor={"black"}
                        deviceType={Phone.ANDROID}
                        scale={65}
                        flowId={DEFAULT_FLOW}
                        isDualMode={false}
                    />
                </Styled.FloatingPreview>
                <Styled.Component>
                    <ProgressIndicator
                        ref={(el: ProgressIndicator) => (loader = el)}
                        isLoading={true}
                    />
                </Styled.Component>
            </Styled.App>
        );
    }
};

export default App
