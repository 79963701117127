import PropTypes, { InferProps } from 'prop-types';
import * as React from 'react';

// @ts-ignore
const Container = ({ children, isFinished, animationDuration }: InferProps<typeof Container.propTypes>) => {
    return (
        <div
            style={{
                opacity: isFinished ? 0 : 1,
                pointerEvents: 'none',
                transition: `opacity ${animationDuration}ms linear`,
            }}
        >
            {children}
        </div>
    );
};

Container.propTypes = {
    animationDuration: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
    isFinished: PropTypes.bool.isRequired,
};

export default Container
