import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Bar from './Bar';
import Container from './Container';
import { NProgress } from "@tanem/react-nprogress";

interface Props {
    isLoading: boolean
}

interface State {
    isLoading: boolean
}

interface RouteProps {
    key: string
}

export class ProgressIndicator extends React.Component<Props, State> {
    mounted: boolean = false;
    state = { isLoading: false };
    
    constructor(props: Props) {
        super(props);
        this.state = { isLoading: props.isLoading };
    }
    
    componentDidMount() {
        this.mounted = true;
    }
    
    render() {
        return (
            <Router>
                <Route render={(props: RouteProps) => (
                    <React.Fragment>
                        <NProgress isAnimating={this.state.isLoading} key={props.key}>
                            {({ isFinished, progress, animationDuration }) => (
                                <Container
                                    isFinished={isFinished}
                                    animationDuration={animationDuration}
                                >
                                    <Bar
                                        progress={progress}
                                        animationDuration={animationDuration}
                                    />
                                </Container>
                            )}
                        </NProgress>
                    </React.Fragment>
                )}
                />
            </Router>
        );
    }
}
