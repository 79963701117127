import Rete from "rete";
import {NodeData, WorkerInputs, WorkerOutputs} from "rete/types/core/data";
import {FlowNode, NetworkNodeData} from "../models/Node";
import {Action, ActionPayload, NetworkRequestAction, NetworkRequestFailureAction} from "../models/Action";
import {actionSocket} from "../Constants";

export class NetworkNodeComponent extends Rete.Component {
    static type: string = "Network";

    constructor() {
        super(NetworkNodeComponent.type);
    }

    builder(node: any) {
        const flowNode = node as FlowNode;
        const nodeData = flowNode.data as NetworkNodeData;
        const actions: Action[] = nodeData.payload.actions || [];

        actions.forEach(action => {
            let payload: ActionPayload;

            if (action.type === "NETWORK_REQUEST") {
                payload = action.payload as NetworkRequestAction.Payload;
                if (!payload) {
                    throw new Error("Could not cast to NetworkRequestAction.Payload: " + action.payload);
                }
            } else if (action.type === "NETWORK_REQUEST_FAILURE") {
                payload = action.payload as NetworkRequestFailureAction.Payload;
                if (!payload) {
                    throw new Error("Could not cast to NetworkRequestFailureAction.Payload: " + action.payload);
                }
            } else {
                throw new Error("Expected network request action in network node! Found " + action.type);
            }

            node.addOutput(
                new Rete.Output(action.id, payload.name, actionSocket, false)
            );
        })

        node.addInput(new Rete.Input("act", "Link", actionSocket, true));
        return node;
    }

    async worker(
        node: NodeData,
        inputs: WorkerInputs,
        outputs: WorkerOutputs,
        nextMessage: unknown,
        onNode = () => {}
    ) {
        // console.log('[ResultNode][Worker] ' + JSON.stringify(node, null, "\t") + '\n\n' + JSON.stringify(nextMessage, null, "\t"))
    }
}
