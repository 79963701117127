import {
    Action,
    ActionPayload,
    ActionType,
    EnumAction,
    FreeResponseAction,
    MultiselectAction,
    PrimaryAction,
    RuleSetAction,
    SecondaryAction,
    TextAction,
    TableAction
} from "../models/Action";
import {Localizations} from "../models/Localization";

export function generateDefaultAction(id: string, currentActionOption: string, localization: Localizations): [Action, Localizations] {
    const key: string = `${id}_TEXT`;
    const name: string = `${currentActionOption.toUpperCase().replace(" ", "_")}_NAME`;
    let type: ActionType;
    let payload: ActionPayload;
    let localizations: Localizations = localization;
    
    if (currentActionOption === "Primary Action") {
        type = "PRIMARY";
        payload = {
            name: name,
            value: null,
            text: key,
            icon: "ARROW"
        } as PrimaryAction.Payload;
    } else if (currentActionOption === "Secondary Action") {
        type = "SECONDARY";
        payload = {
            name: name,
            value: null,
            text: key
        } as SecondaryAction.Payload;
    } else if (currentActionOption === "Enum Action") {
        type = "ENUM";
        payload = {
            name: name,
            value: null,
            text: key
        } as EnumAction.Payload;
    } else if (currentActionOption === "Free Response") {
        const placeholderKey = `${id}_PLACEHOLDER_TEXT`;
        type = "FREE_RESPONSE";
        payload = {
            name: name,
            value: { type: "STRING" },
            placeholder: placeholderKey,
            submitText: key,
            submitIcon: "ARROW"
        } as FreeResponseAction.Payload;

        if (!localizations[placeholderKey]) localizations[placeholderKey] = {"en-US": "Submit"};
    } else if (currentActionOption === "Multiple Selection") {
        const choiceKeyA = `${id}_CHOICE_TEXT_A`;
        const choiceKeyB = `${id}_CHOICE_TEXT_B`;
        const choiceKeyC = `${id}_CHOICE_TEXT_C`;
        const choiceKeyD = `${id}_CHOICE_TEXT_D`;

        type = "MULTI_SELECT";
        payload = {
            name: name,
            value: { type: "ARRAY" },
            choices: [
                { text: choiceKeyA, value: "HEADACHE" },
                { text: choiceKeyB, value: "FEVER" },
                { text: choiceKeyC, value: "COUGH" }
            ],
            noSelection: { text: choiceKeyD, value: "None of the above" },
            submitText: key,
            submitIcon: "ARROW"
        } as MultiselectAction.Payload;

        if (!localizations[choiceKeyA]) localizations[choiceKeyA] = {"en-US": "Headache"};
        if (!localizations[choiceKeyB]) localizations[choiceKeyB] = {"en-US": "Fever"};
        if (!localizations[choiceKeyC]) localizations[choiceKeyC] = {"en-US": "Cough"};
        if (!localizations[choiceKeyD]) localizations[choiceKeyD] = {"en-US": "None of the above"};
    } else if (currentActionOption === "Rule Set") {
        type = "RULESET";
        payload = {
            name: name,
            value: null,
            operator: "AND",
            rules: [
                { 
                    operator: "AND",
                    functions: [
                        {
                            evaluate: "∆VALUE",
                            comparator: "DOES_NOT_EQUAL",
                            value: "SOME_RANDOM_COMPARISON_VALUE"
                        }
                    ]
                },
                {
                    operator: "DEFAULT",
                    functions: []
                }
            ]
        } as RuleSetAction.Payload;
    } else {
        throw new Error("Could not match on selected action type string: " + currentActionOption);
    }

    if (!localizations[key]) localizations[key] = {"en-US": currentActionOption};

    return [
        {
            id: id,
            variableAssignments: {},
            valueTransform: null,
            type: type,
            payload: payload
        },
        localizations
    ];
}

export function getUpdatedPayload(
    type: ActionType,
    anyPayload: ActionPayload,
    value: any,
    localization: Localizations | undefined = undefined
): [ActionPayload, Localizations] {
    let updatedPayload: ActionPayload;
    let localizations = localization || {};
    
    if (type === "ENUM") {
        const payload = anyPayload as EnumAction.Payload;
        if (!payload) { throw Error("Could not cast payload to EnumAction type! " + anyPayload); }
        localizations[payload.text]["en-US"] = value;
        updatedPayload = payload;
    } else if (type === "PRIMARY") {
        const payload = anyPayload as PrimaryAction.Payload;
        if (!payload) { throw Error("Could not cast payload to PrimaryAction type! " + anyPayload); }
        localizations[payload.text]["en-US"] = value;
        updatedPayload = payload;
    } else if (type === "FREE_RESPONSE") {
        const payload = anyPayload as FreeResponseAction.Payload;
        if (!payload) { throw Error("Could not cast payload to FreeResponse type! " + anyPayload); }
        localizations[payload.submitText]["en-US"] = value;
        updatedPayload = payload;
    } else if (type === "SECONDARY") {
        const payload = anyPayload as SecondaryAction.Payload;
        if (!payload) { throw Error("Could not cast payload to SecondaryAction type! " + anyPayload); }
        localizations[payload.text]["en-US"] = value;
        updatedPayload = payload;
    } else if (type === "MULTI_SELECT") {
        const payload = anyPayload as MultiselectAction.Payload;
        if (!payload) { throw Error("Could not cast payload to MultiSelect type! " + anyPayload); }
        localizations[payload.submitText]["en-US"] = value;
        updatedPayload = payload;
    } else if (type === "RULESET") {
        const payload = anyPayload as RuleSetAction.Payload;
        if (!payload) { throw Error("Could not cast payload to RuleSet type! " + anyPayload); }
        // TODO: This needs to update the payload.rulesets (not just the label).
        payload.name = value;
        updatedPayload = payload;
    } else if (type === "TEXT") {
        const payload = anyPayload as TextAction.Payload;
        if (!payload) { throw Error("Could not cast payload to Text type! " + anyPayload); }
        localizations[payload.text]["en-US"] = value;
        updatedPayload = payload;
    } else {
        throw new Error("Invalid action type! " + type);
    }
    
    return [updatedPayload, localizations || {}];
}

export function outputTitleForAction(action: Action, localizations: Localizations = {}): string {
    if (action.type === "ENUM") {
        const payload = action.payload as EnumAction.Payload;
        if (!payload) { throw Error("Could not cast payload to EnumAction type! " + action.payload); }
        return localizations[payload.text]["en-US"];
    } else if (action.type === "PRIMARY") {
        const payload = action.payload as PrimaryAction.Payload;
        if (!payload) { throw Error("Could not cast payload to PrimaryAction type! " + action.payload); }
        return localizations[payload.text]["en-US"];
    } else if (action.type === "FREE_RESPONSE") {
        const payload = action.payload as FreeResponseAction.Payload;
        if (!payload) { throw Error("Could not cast payload to FreeResponse type! " + action.payload); }
        return localizations[payload.submitText]["en-US"];
    } else if (action.type === "SECONDARY") {
        const payload = action.payload as SecondaryAction.Payload;
        if (!payload) { throw Error("Could not cast payload to SecondaryAction type! " + action.payload); }
        return localizations[payload.text]["en-US"];
    } else if (action.type === "TEXT") {
        const payload = action.payload as TextAction.Payload;
        if (!payload) { throw Error("Could not cast payload to TextAction type! " + action.payload); }
        return (Object.keys(localizations).includes(payload.text)) ? localizations[payload.text]["en-US"] : payload.name;
    } else if (action.type === "TABLE") {
        const payload = action.payload as TableAction.Payload;
        if (!payload) { throw Error("Could not cast payload to TableAction type! " + action.payload); }
        if (payload.table.title) {
            return localizations[payload.table.title]["en-US"];
        } else {
            return "TABLE";
        }
    } else if (action.type === "MULTI_SELECT") {
        const payload = action.payload as MultiselectAction.Payload;
        if (!payload) { throw Error("Could not cast payload to Multiselect type! " + action.payload); }
        return localizations[payload.submitText]["en-US"];
    } else if (action.type === "RULESET") {
        const payload = action.payload as RuleSetAction.Payload;
        if (!payload) { throw Error("Could not cast payload to RuleSet type! " + action.payload); }
        return payload.name;
    } else {
        const payload = action.payload as ActionPayload;
        if (!payload) { throw Error("Could not cast payload " + action.payload); }
        return payload.name;
    }
}

export function outputTitleIdForAction(action: Action): string {
    if (action.type === "ENUM") {
        const payload = action.payload as EnumAction.Payload;
        if (!payload) { throw Error("Could not cast payload to EnumAction type! " + action.payload); }
        return payload.text;
    } else if (action.type === "PRIMARY") {
        const payload = action.payload as PrimaryAction.Payload;
        if (!payload) { throw Error("Could not cast payload to PrimaryAction type! " + action.payload); }
        return payload.text;
    } else if (action.type === "FREE_RESPONSE") {
        const payload = action.payload as FreeResponseAction.Payload;
        if (!payload) { throw Error("Could not cast payload to FreeResponse type! " + action.payload); }
        return payload.submitText;
    } else if (action.type === "SECONDARY") {
        const payload = action.payload as SecondaryAction.Payload;
        if (!payload) { throw Error("Could not cast payload to SecondaryAction type! " + action.payload); }
        return payload.text;
    } else if (action.type === "MULTI_SELECT") {
        const payload = action.payload as MultiselectAction.Payload;
        if (!payload) { throw Error("Could not cast payload to Multiselect type! " + action.payload); }
        return payload.submitText;
    } else if (action.type === "TEXT") {
        const payload = action.payload as TextAction.Payload;
        if (!payload) { throw Error("Could not cast payload to Text type! " + action.payload); }
        return payload.text;
    } else if (action.type === "TABLE") {
        const payload = action.payload as TableAction.Payload;
        if (!payload) { throw Error("Could not cast payload to Table type! " + action.payload); }
        return payload.name;
    } else if (action.type === "RULESET") {
        const payload = action.payload as RuleSetAction.Payload;
        if (!payload) { throw Error("Could not cast payload to RuleSet type! " + action.payload); }
        return payload.name;
    } else {
        const payload = action.payload as ActionPayload;
        if (!payload) { throw Error("Could not cast payload to type! " + action.payload); }
        return payload.name;
    }
}
