import React from "react";
import styled from "styled-components";
import Rete from "rete";

const Styled = {
    Divider: styled.hr`
    border: 1px solid #bbbbbb;
    border-radius: 2px;
    width: 25%;
    margin-top: 8px;
    margin-left: 8px;
    position: absolute;
  `,
};

class ReactDividerControl extends React.Component {
    componentDidMount() {}
    
    render() {
        return <Styled.Divider/>;
    }
}

export class DividerControl extends Rete.Control {
    emitter: any;
    component: any;
    render: any;
    props: any;
    
    constructor(emitter: any, key: string) {
        super(key);
        this.render = "react";
        this.component = ReactDividerControl;
        this.props = { emitter };
    }
}
