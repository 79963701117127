import Rete from "rete";
import {NodeData, WorkerInputs, WorkerOutputs} from "rete/types/core/data";
import {FlowNode, WebModalNodeData} from "../models/Node";
import {Action, CloseModalNavigationAction} from "../models/Action";
import {actionSocket} from "../Constants";

export class WebModalNodeComponent extends Rete.Component {
    static type: string = "WebModal";

    constructor() {
        super(WebModalNodeComponent.type);
    }

    builder(node: any) {
        const flowNode = node as FlowNode;
        const nodeData = flowNode.data as WebModalNodeData;
        const actions: Action[] = nodeData.payload.actions || [];

        actions.forEach(action => {
            if (action.type !== "CLOSE_MODAL_NAVIGATION") {
                throw new Error("Expected CloseModalNavigationAction in web modal node! Found " + action.type);
            }
            const payload = action.payload as CloseModalNavigationAction.Payload;
            if (!payload) {
                throw new Error("Could not cast to CloseModalNavigationAction.Payload: " + action.payload);
            }

            node.addOutput(
                new Rete.Output(action.id, payload.name, actionSocket, false)
            );
        })

        node.addInput(new Rete.Input("act", "Link", actionSocket, true));
        return node;
    }

    async worker(
        node: NodeData,
        inputs: WorkerInputs,
        outputs: WorkerOutputs,
        nextMessage: unknown,
        onNode = () => {}
    ) {
        // console.log('[WebModalNode][Worker] ' + JSON.stringify(node, null, "\t") + '\n\n' + JSON.stringify(nextMessage, null, "\t"))
    }
}
