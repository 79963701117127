import React from "react";
import styled from "styled-components";
import "react-dropdown/style.css";
import { Icon } from "../views/Icon";
import { deselectAll, editor, nodeFor, updateNodes } from "../Editor";
import { BotInputInspectorPanel, HeaderItem } from "./BotInputInspectorPanel";
import { RouterInspectorPanel } from "./RouterInspectorPanel";
import { Node } from "rete/types";
import {ResultInspectorPanel} from "./ResultInspectorPanel";
import {BotInputNodeData, FlowNodeData} from "../models/Node";

// MARK: Constants

const DefaultWidth = 311;

// MARK: Types

interface Props {
    id?: number;
}

interface State {
    isVisible: boolean;
    id?: number;
}

// MARK: Styles

export const Styled = {
    Container: styled.div`
    position: absolute;
    height: 100%;
    width: ${DefaultWidth}px;
    background-color: #fff;
    opacity: 1;
    overflow: hidden;
    top: 0;
    right: -${DefaultWidth}px;
    transform: translateX(${DefaultWidth}px);
    padding-left: 20px;
    box-shadow: -4px 0px 40px rgba(26, 26, 73, 0);
    transition: all 0.25s cubic-bezier(0.05, 0.03, 0.35, 1);
  `,
    Input: styled.input`
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 8px rgba(34, 34, 87, 0.05);
    color: rgba(0, 0, 0, 0.77);
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: normal;
    margin: 0;
    margin-bottom: 10px;
    padding: 10px 14px;
    text-indent: 0px;
    text-rendering: auto;
    text-shadow: none;
    text-transform: none;
    width: calc(100% - 50px);
    word-spacing: normal;
  `,
    Header: styled.p`
    font-size: 20px;
    font-family: Roboto;
    font-weight: bold;
    color: #393c44;
    margin-top: 101px;
  `,
    Subheading: styled.p`
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: 14px;
    font-family: Roboto;
    font-weight: bold;
    color: #393c44;
  `,
    CheckboxLabel: styled.p`
      margin: 4px 0;
      padding: 4px 0;
      &.disabled {
        opacity: 0.5;
      }
    `,
    PropertyList: styled.div`
    height: calc(100% - 265px);
    overflow: auto;
    margin-top: -10px;
    padding-top: 20px;
  `,
    Row: styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-left: 0;
    padding: 0;
  `,
    AddDiv: styled.div`
    margin-bottom: 10px;
    margin-right: 0;
    margin-top: 0;
    cursor: pointer;

    Icon {
      margin-top: 17px;
      float: right;
      margin-right: 15px;
    }
    p {
      display: block;
      padding: 12px 16px;
      font-family: Roboto;
      font-size: 14px;
    }
  `,
    InputDiv: styled.div`
    margin-bottom: 10px;
    Icon {
      display: inline-block;
      vertical-align: middle;
    }
    input {
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.1);
      box-shadow: 0px 2px 8px rgba(34, 34, 87, 0.05);
      color: rgba(0, 0, 0, 0.77);
      display: inline-block;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: normal;
      margin: 0;
      margin-right: 4px;
      margin-top: -3px;
      padding: 10px 14px;
      text-indent: 0px;
      text-rendering: auto;
      text-shadow: none;
      text-transform: none;
      vertical-align: middle;
      width: calc(100% - 76px);
      word-spacing: normal;
    }
  `,
    CheckboxDiv: styled.div`
    margin-bottom: 0px;
    padding: 0;
    margin-top: 0px;
    Icon {
      display: inline-block;
      vertical-align: middle;
    }
    p {
      display: inline-block;
      font-family: Roboto;
      font-size: 14px;
      vertical-align: middle;
      margin-left: 10px;
    }
    
    &.disabled {
        pointer-events: none; 
        -webkit-user-select: none; 
        -moz-user-select: none;
        -ms-user-select: none; 
        user-select: none;
        opacity: 0.7;
        
        :focus { 
            outline: 0; 
        }
    }
  `,
    ActionList: styled.div`
    display: inline-block;
    vertical-align: center;
    height: 92px;
    z-index: 1000;
  `,
    Divider: styled.div`
    height: 1px;
    width: 100%;
    background-color: #e8e8ef;
    position: absolute;
    right: 0;
    bottom: 110px;
  `,
    UpdateBlock: styled.div`
        display: inline-block;
        vertical-align: middle;
        margin-left: 0;
        padding: 0;
        position: absolute;
        bottom: 58px;
        border-radius: 5px;
        font-family: Roboto, serif;
        font-size: 14px;
        text-align: center;
        width: 287px;
        height: 38px;
        line-height: 38px;
        color: #253134;
        border: 1px solid #e8e8ef;
        transition: all 0.3s cubic-bezier(0.05, 0.03, 0.35, 1);
        :hover {
            cursor: pointer;
            opacity: 0.5;
        }
    `,
    RemoveBlock: styled.div`
        position: absolute;
        bottom: 10px;
        border-radius: 5px;
        font-family: Roboto, serif;
        font-size: 14px;
        text-align: center;
        width: 287px;
        height: 38px;
        line-height: 38px;
        color: #ff1313;
        border: 1px solid #e8e8ef;
        transition: all 0.3s cubic-bezier(0.05, 0.03, 0.35, 1);
        :hover {
            cursor: pointer;
            opacity: 0.5;
        }
    `,
    InspectorBlock: styled.div`
        margin-bottom: 30px;
    `,
    CloseRow: styled.div`
        display: inline-block;
        vertical-align: middle;
        margin-left: 0;
        padding: 0;
        margin-top: -45px;
        position: absolute;
        right: 20px;
        z-index: 9999;
        transition: all 0.25s cubic-bezier(0.05, 0.03, 0.35, 1);
    `,
    Properties: styled.div`
        display: inline-block;
        vertical-align: middle;
        margin-left: 0;
        padding: 0;
        position: absolute;
        height: 100%;
        width: 311px;
        background-color: #fff;
        opacity: 1;
        overflow: hidden;
        top: 0;
        right: -311px;
        transform: translateX(311px);
        padding-left: 20px;
        box-shadow: -4px 0px 40px rgba(26, 26, 73, 0);
        transition: all 0.25s cubic-bezier(0.05, 0.03, 0.35, 1);
        
        &.properties.hidden {
            transform: translateX(311px);
            z-index: 2;
            box-shadow: -4px 0px 40px rgba(26, 26, 73, 0.05);
        }
        
        &.properties.expanded {
            transform: translateX(-311px);
            opacity: 1 !important;
            z-index: 2;
            box-shadow: -4px 0px 40px rgba(26, 26, 73, 0.05);
        }
    `
};

export class InspectorPanel extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        
        this.state = {
            isVisible: false,
            id: props.id
        };
    }
    
    componentWillReceiveProps(props: Props) {
        this.setState(props);
    }
    
    render() {
        if (!this.state.id) return [];
        const node = nodeFor(this.state.id);
        if (!node) return [];
        
        return (
            <Styled.Properties
                id="properties"
                key="inspector-properties"
                className={`properties ${this.state.isVisible ? "expanded" : "hidden"}`}
            >
                <Styled.Header key="inspector-header">Inspector</Styled.Header>
                <Styled.CloseRow
                    key="close-inspector"
                    onClick={async () => {
                        this.setVisibility(false);
                        await deselectAll();
                    }}>
                    <Icon graphic="CLOSE" key="CLOSE-INSPECTOR-ICON"/>
                </Styled.CloseRow>
                {this.renderInspectorPanel(node)}
                <Styled.Divider key={"inspector-divider"}/>
                <Styled.ActionList key="action-list" id="action-list">
                    <Styled.UpdateBlock key={"update-node-action"} onClick={async () => await this.updateProperties()}>
                        Update node
                    </Styled.UpdateBlock>
                    <Styled.RemoveBlock key={"remove-node-action"} onClick={async () => await this.removeNode()}>
                        Delete node
                    </Styled.RemoveBlock>
                </Styled.ActionList>
            </Styled.Properties>
        )
    }
    
    renderInspectorPanel(node: Node) {
        const nodeData: FlowNodeData = node.data as unknown as FlowNodeData;

        switch (node.name) {
            case "BotInput":
                const data: BotInputNodeData = nodeData as BotInputNodeData;
                if (!data) {
                    throw new Error("Could not case to BotInputNode: " + nodeData.data);
                }
                
                return (
                    <BotInputInspectorPanel
                        key={"bot-input-inspector-panel-" + data.id + "-" + data.type}
                        nodeId={node.id}
                        actions={data.payload.actions}
                        body={data.payload.body}
                        header={[HeaderItem.BACK_NAVIGATION]} // TODO: Fix this
                        label={data.label}
                        localizations={data.payload.localizations}
                    />
                );
            case "Router":
                return (
                    <RouterInspectorPanel
                        id={node.id}
                        key={"router-inspector-panel-" + node.id + "-" + node.name}
                    />
                );
            case "Result":
                return (
                    <ResultInspectorPanel
                        id={node.id}
                        key={"result-inspector-panel-" + node.id + "-" + node.name}
                    />
                );
            case "Network":
                return (<></>);
                /*return (
                    <RouterInspectorPanel
                        id={node.id}
                        key={"network-inspector-panel-" + node.id + "-" + flowNode.type}
                    />
                );*/
            case "WebModal":
                return (<></>);
                /*return (
                    <RouterInspectorPanel
                        id={node.id}
                        key={"web-modal-inspector-panel-" + node.id + "-" + flowNode.type}
                    />
                );*/
            case "CustomModal":
                return (<></>);
                /*return (
                    <RouterInspectorPanel
                        id={node.id}
                        key={"custom-modal-inspector-panel-" + node.id + "-" + flowNode.type}
                    />
                );*/
            default:
                throw Error("Unknown node type!");
        }
    }
    
    setVisibility(isOn: boolean) {
        this.setState({ isVisible: isOn });
    }
    
    async removeNode() {
        if (!this.state.id) throw new Error();
        const node = nodeFor(this.state.id);
        
        if (!node) {
            console.log(
                "[Inspector][ERROR] Attempt to remove a node that does not exist! ID: " + this.state.id
            );
            return;
        }
        
        editor.removeNode(node);
    }
    
    async updateProperties() {
        if (!this.state.id) throw new Error();
        const node = nodeFor(this.state.id);
        
        if (!node) {
            console.log(
                "[Inspector][ERROR] Attempt to remove a node that does not exist! ID: " + this.state.id
            );
            return;
        }
        
        await updateNodes();
    }
}
