import React from "react";
import { Icon } from "../views/Icon";
import { nodeFor } from "../Editor";
import Dropdown from "react-dropdown";
import { getUpdatedPayload } from "../helpers/ActionHelpers";
import { Styled } from "./Inspector"
import {FlowNode, RouterNodeData} from "../models/Node";
import {Action, Rule, RuleSetAction} from "../models/Action";

const RulesetOptions: string[] = [
    "EQUALS",
    "DOES_NOT_EQUAL",
    "INCLUDES",
    "DOES_NOT_INCLUDE",
    "IS_GREATER_THAN",
    "IS_LESS_THAN",
    "IS_GREATER_THAN_OR_EQUAL_TO",
    "IS_LESS_THAN_OR_EQUAL_TO"
];

const DefaultRulesetOption = RulesetOptions[3] as string;
let currentRulesetOption = RulesetOptions[3] as string;

interface Props {
    id: number;
}

export class RouterInspectorPanel extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.setValue = this.setValue.bind(this);
    }
    
    componentWillReceiveProps(props: Props) {
        this.setState(props);
    }
    
    render(): React.ReactNode {
        const node = nodeFor(this.props.id);
        if (!node) throw new Error();
        const flowNode: FlowNode = node as unknown as FlowNode;
        if (!flowNode) throw new Error();
        let data: RouterNodeData = flowNode.data as unknown as RouterNodeData;
        if (!data) throw new Error();
        
        const label = flowNode.data.label;
        const actions = data.payload.actions;
        
        return (
            <Styled.PropertyList key="property-list">
                <Styled.Subheading key="name-label">NAME</Styled.Subheading>
                <Styled.Input
                    id="label"
                    key="label"
                    value={label}
                    onChange={this.setValue}
                />
                {this.generateRawRuleset(actions)}
            </Styled.PropertyList>
        );
    }
    
    generateRawRuleset(actionElements: Action[]) {
        return [
            <Styled.Subheading key="rule-label">RULES</Styled.Subheading>,
            actionElements.map((action, index) => {
                const payload = action.payload as RuleSetAction.Payload;
                
                return payload.rules.map((ruleset: Rule, rulesetIndex: number) => {
                    const value: string = JSON.stringify(ruleset);
                    return (
                        <Styled.InputDiv id={"div-rule-" + index + "-" + rulesetIndex}
                                         key={"div-rule-" + index + "-" + rulesetIndex}>
                            <Styled.Input
                                id={"rule-" + index + "-" + rulesetIndex}
                                key={"prop-node-rule-" + index + "-" + rulesetIndex}
                                value={value}
                                onChange={this.setValue}
                            />
                            <Styled.Row
                                id={"rule-row-" + index + "-" + rulesetIndex}
                                key={"prop-node-rule-row-" + index + "-" + rulesetIndex}
                                onClick={async () => {
                                    const node = nodeFor(this.props.id);
                                    if (!node) throw new Error();
                                    const flowNode: FlowNode = node as unknown as FlowNode;
                                    if (!flowNode) throw new Error();
                                    let data: RouterNodeData = flowNode.data as unknown as RouterNodeData;
                                    if (!data) throw new Error();
                                    
                                    data.payload.actions.splice(index, 1);
                                    flowNode.data = data;
                                    node.data = flowNode.data as unknown as { [key: string]: unknown };
                                    await node.update();
                                    this.setState({ actions: data.payload.actions });
                                }}
                            >
                                <Icon key={"close-rule-row-" + index + "-" + rulesetIndex} graphic="CLOSE"/>
                            </Styled.Row>
                        </Styled.InputDiv>
                    );
                })
            }),
            <Styled.AddDiv key="add-rule-div">
                <Dropdown
                    className="dropdown-menu-item"
                    controlClassName="dropdown-menu"
                    placeholderClassName="dropdown-menu-item"
                    menuClassName="dropdown-menu-items-container"
                    options={RulesetOptions}
                    onChange={this.onSelectRulesetType}
                    value={DefaultRulesetOption}
                    placeholder="Add rule"
                />
                <Styled.Row
                    onClick={async () => {
                        const node = nodeFor(this.props.id);
                        if (!node) throw new Error();
                        const flowNode: FlowNode = node as unknown as FlowNode;
                        if (!flowNode) throw new Error();
                        let data: RouterNodeData = flowNode.data as unknown as RouterNodeData;
                        if (!data) throw new Error();
                        
                        // TODO: This
                        console.log(currentRulesetOption);
                        //const condition = ruleConditionFromString(currentRulesetOption);
                        //data.payload.actions.push(routerActionFor(node.id, condition));
                        //flowNode.data = data;
                        //node.data = flowNode.data as unknown as { [key: string]: unknown };
                        //await node.update();
                        this.setState({ actions: data.payload.actions });
                    }}
                >
                    <Icon graphic="ADD"/>
                </Styled.Row>
            </Styled.AddDiv>,
        ];
    }
    
    async setValue(event: { target: { value: any; id: any } }) {
        const { value, id } = event.target;
        
        const node = nodeFor(this.props.id);
        if (!node) throw new Error("ERROR! No node with id: " + this.props.id);
        const flowNode: FlowNode = node as unknown as FlowNode;
        if (!flowNode) throw new Error();
        let data: RouterNodeData = flowNode.data as unknown as RouterNodeData;
        if (!data) throw new Error();
        
        if (id === "label") {
            flowNode.data.label = value;
            node.data = flowNode.data as unknown as { [key: string]: unknown };
            await node.update();
            this.setState({ label: value });
        } else if (id.startsWith("actions-")) {
            const actionId = id.split("-")[1] as number;
            const updatedPayload = getUpdatedPayload(
                data.payload.actions[actionId].type,
                data.payload.actions[actionId].payload!,
                value
            );
            data.payload.actions[actionId].payload = updatedPayload[0] as RuleSetAction.Payload;
            flowNode.data = data;
            node.data = flowNode.data as unknown as { [key: string]: unknown };
            await node.update();
            this.setState({ actions: data.payload.actions });
        } else {
            console.log("[Unknown][" + id + "] " + value);
        }
    }
    
    onSelectRulesetType(event: { value: string }) {
        currentRulesetOption = event.value;
    }
}