import React from "react";
import styled from "styled-components";
import { Icon } from "../../views/Icon";
import Rete from "rete";

const Styled = {
    StatusContainer: styled.div`
    height: 72px;
    padding: 0;
    margin-left: 3px;
    margin-top: 6px;
    margin-bottom: -8px;
  `,
};

class ReactStatusControl extends React.Component {
    componentDidMount() {}
    
    render() {
        const dataProp: {} = { awaitingInput: true };
        return (
            <Styled.StatusContainer>
                <Icon graphic="BOT" data={`${dataProp}`}/>
            </Styled.StatusContainer>
        );
    }
}

export class StatusControl extends Rete.Control {
    render: any;
    component: any;
    props: any;
    
    constructor(emitter: any, key: string, value: string) {
        super(key);
        this.render = "react";
        this.component = ReactStatusControl;
        this.props = { emitter, value };
    }
}
