import * as Rete from "rete";
import { NodeData, WorkerInputs, WorkerOutputs } from "rete/types/core/data";
import {FlowNode, RouterNodeData} from "../models/Node";
import {Action, RuleSetAction} from "../models/Action";
import {actionSocket} from "../Constants";

export class RouterNodeComponent extends Rete.Component {
    static type: string = "Router";
    
    constructor() {
        super(RouterNodeComponent.type);
    }
    
    builder(node: any) {
        const flowNode = node as FlowNode;
        const nodeData = flowNode.data as RouterNodeData;
        const actions: Action[] = nodeData.payload.actions || [];
        
        actions.forEach(action => {
            if (action.type !== "RULESET") {
                throw new Error("Expected ruleset in router node action! Found " + action.type);
            }

            const payload = action.payload as RuleSetAction.Payload;

            if (!payload) {
                throw new Error("Could not cast to RuleSetPayload: " + action.payload);
            }
            
            node.addOutput(
                new Rete.Output(action.id, payload.name, actionSocket, false)
            );
        })
        
        node.addInput(new Rete.Input("act", "Link", actionSocket, true));
        return node;
    }
    
    async worker(
        node: NodeData,
        inputs: WorkerInputs,
        outputs: WorkerOutputs,
        nextMessage: unknown,
        onNode = () => {}
    ) {
        // console.log('[RouterNode][Worker] ' + JSON.stringify(node, null, "\t") + '\n\n' + JSON.stringify(nextMessage, null, "\t"))
    }
}
