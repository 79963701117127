import * as Rete from "rete";
import {Node} from "rete/types";
import {NodeData, WorkerInputs, WorkerOutputs} from "rete/types/core/data";
import {BodyControl} from "./controls/BodyControl";
import {StatusControl} from "./controls/StatusControl";
import {DividerControl} from "./controls/DividerControl";
import {outputTitleIdForAction} from "../helpers/ActionHelpers";
import {BotInputNodeData, BotInputNodeDataPayload} from "../models/Node";
import {Action, PrimaryAction} from "../models/Action";
import {actionSocket} from "../Constants";

export class BotInputNodeComponent extends Rete.Component {
    static type = "BotInput";
    
    constructor() {
        super(BotInputNodeComponent.type);
    }
    
    async builder(node: Node) {
        if (Object.keys(node.data).length === 0) {
            node.data = new BotInputNodeData(
                `NODE_${node.id}`,
                new BotInputNodeDataPayload(
                    [
                        {
                            "id": `${node.id}_0`,
                            "type": "PRIMARY",
                            "valueTransform": null,
                            "variableAssignments": {},
                            "payload": {
                                "name": `ACTION_${node.id}_0`,
                                "value": null,
                                "text": "ACTION_0",
                                "icon": "ARROW"
                            } as PrimaryAction.Payload
                        }
                    ],
                    "NORMAL",
                    {
                        "BODY_0": {"en-US": "A message from the bot."},
                        "ACTION_0": {"en-US": "Continue"}
                    },
                    ["BODY_0"]
                ),
                "BotInput"
            );

            node.update();
        }

        const flowNodeData = node.data as BotInputNodeData;
        let nodeData = flowNodeData.payload as BotInputNodeDataPayload;
        const actions: Action[] = nodeData.actions || [];
        const body: string[] = nodeData.body || [];
        const localizations = nodeData.localizations || {};

        node.addControl(
            new StatusControl(this.editor, `${node.id}-STATUS`, "awaitingInput") as any
        );
        
        body.forEach((item, index) => {
            const id = item;
            if (!localizations[id] || !localizations[id]["en-US"]) {
                nodeData.localizations[id]["en-US"] = id;
            }
            
            const control = new BodyControl(
                this.editor,
                `${node.id}-BODY-${index}`,
                nodeData.localizations[id]["en-US"],
                true
            );
            
            node.addControl(control as any);
        });

        if (body.length > 0) {
            node.addControl(new DividerControl(this.editor, `${node.id}-DIVIDER`) as any);
        }

        node.addInput(new Rete.Input("act", "Link", actionSocket, true));
        node.addInput(new Rete.Input("modal", "Link", actionSocket, true));
        node.addInput(new Rete.Input("error", "Link", actionSocket, true));

        actions.forEach((action) => {
            const id = outputTitleIdForAction(action);

            var title = id;
            if (Object.keys(nodeData.localizations).includes(id)) {
                title = nodeData.localizations[id]["en-US"];
            }

            node.addOutput(
                new Rete.Output(action.id, title, actionSocket, false)
            );
        });

        return node;
    }
    
    async worker(
        node: NodeData,
        inputs: WorkerInputs,
        outputs: WorkerOutputs,
        nextMessage: unknown,
        onNode = () => {
        }
    ) {
        // console.log('[BotInputNode][Worker] ' + JSON.stringify(node, null, "\t") + '\n\n' + JSON.stringify(nextMessage, null, "\t"))
    }
}
