import React from "react";
import styled from "styled-components";
import Rete from "rete";
import { BotTextarea } from "../../views/BotTextarea";

const Styled = {
    BotText: styled.div`
        margin: 8px;
        padding: 0;
        resize: none;
        overflow: hidden;
    `
};

interface Props {
    id: string;
    body: string;
}

interface State {
    id: string;
    body: string;
}

export class ReactBodyControl extends React.Component<Props, State> {
    state: State = {
        id: "",
        body: "DEFAULT_BOT_MESSAGE",
    };
    
    constructor(props: Props) {
        super(props);
        
        this.state = {
            id: props.id,
            body: props.body
        };
        
        this.setValue = this.setValue.bind(this);
    }
    
    setValue(event: any) {
        const id = event.id;
        if (!id) return;
        const { value } = event.target;
        this.setState({ id: value });
    }
    
    componentDidMount() {
        this.setState({
            id: this.props.id,
            body: this.props.body,
        });
    }
    
    componentWillReceiveProps(props: Props) {
        this.setState(props);
    }
    
    generateTextArea() {
        const body: string = this.state.body;
        if (!body) return [];
        
        const id: string = this.state.id;
        return (
            <BotTextarea
                key={`bot-textarea-primary-${id}`}
                id={id}
                value={body}
                height={0}
            />
        )
    }
    
    render() {
        return (
            <Styled.BotText>
                {this.generateTextArea()}
            </Styled.BotText>
        );
    }
}

export class BodyControl extends Rete.Control {
    emitter: any;
    render: any;
    component: any;
    props: any;
    key: any;
    
    constructor(
        emitter: any,
        key: string,
        body: string,
        readonly: boolean = false
    ) {
        super(key);
        this.emitter = emitter;
        this.key = key;
        this.render = "react";
        this.component = ReactBodyControl;

        this.props = {
            readonly,
            id: key,
            body,
        };
    }
    
    update() {
        this.putData(this.key, this.state.body);
        this.emitter.trigger("process" as any);
    }
    
    setValue(value: any) {
        this.state.value = value;
        this.component.putData(value);
        this.putData(this.key, value);
        this.update();
    }
}
