import {FlowNode} from "./Node";
import {Locale} from "./Localization";
import {Variables} from "./Value";

export class Flow {
    id: string;
    version: number;
    updated: number;
    schema: number;
    runners: FlowRunner[];
    variables: Variables;
    parameters: Variables;
    locales: Locale[];
    nodes: FlowNode[];

    constructor(
        id: string,
        version: number,
        updated: number,
        schema: number,
        runners: FlowRunner[],
        variables: Variables,
        parameters: Variables,
        locales: Locale[],
        nodes: FlowNode[]
    ) {
        this.id = id;
        this.version = version;
        this.updated = updated;
        this.schema = schema;
        this.runners = runners;
        this.variables = variables;
        this.parameters = parameters;
        this.locales = locales;
        this.nodes = nodes;
    }
}

export type FlowRunner =
    | "IOS_PATIENT_CLIENT"
    | "ANDROID_PATIENT_CLIENT"
    | "EVENT_STREAM_OBSERVER_SERVICE"