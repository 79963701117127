import {Flow, FlowRunner} from "./models/Flow";
import {BotInputNodeData, FlowNode} from "./models/Node";
import {PrimaryAction, SecondaryAction, TextAction} from "./models/Action";
import {Locale} from "./models/Localization";
import {Variable} from "./models/Value";

export function emptyFlow(
    flowId: string,
    flowVersion: number = 1,
    flowUpdated: number = Math.round(Date.now() / 1000),
    flowSchema: number = 1,
    flowRunners: FlowRunner[] = ["IOS_PATIENT_CLIENT", "ANDROID_PATIENT_CLIENT"],
    flowVariables: { [key: string]: Variable } = {},
    flowParameters: { [key: string]: Variable } = { "LOCALE": { type: "STRING", isNullable: false, isMutable: false }},
    flowLocales: Locale[] = ["en-US"],
    flowNodes: FlowNode[] = [entryNodeTemplate]
): Flow {
    const flow: Flow = {
        id: flowId,
        version: flowVersion,
        updated: flowUpdated,
        schema: flowSchema,
        runners: flowRunners,
        variables: flowVariables,
        parameters: flowParameters,
        locales: flowLocales,
        nodes: flowNodes
    };
    
    return flow;
}

const botInputNode: BotInputNodeData = {
    label: "ENTRY_NODE",
    type: "BotInput",
    payload: {
        actions: [
            {
                id: "1_0",
                valueTransform: null,
                variableAssignments: {},
                payload: {
                    name: "REVIEW_TERMS",
                    value: null,
                    style: "DETAIL",
                    text: "REVIEW_TERMS"
                },
                type: "TEXT"
            } as TextAction,
            {
                id: "1_1",
                valueTransform: null,
                variableAssignments: {},
                payload: {
                    name: "REJECT_TERMS",
                    value: null,
                    text: "I_DO"
                },
                type: "SECONDARY"
            } as SecondaryAction,
            {
                id: "1_2",
                valueTransform: null,
                variableAssignments: {},
                payload: {
                    name: "ACCEPT_TERMS",
                    value: null,
                    text: "I_AGREE",
                    icon: "ARROW"
                },
                type: "PRIMARY"
            } as PrimaryAction
        ],
        presentationSpeed: "NORMAL",
        localizations: {
            "THIS_ASSESSMENT_0": {
                "en-US": "This assessment is based on the Centers for Disease Control (CDC) guidelines and will quickly take you through questions about COVID-19 symptoms to help you know what to do next."
            },
            "THIS_ASSESSMENT_1": {
                "en-US": "This assessment tool is not a substitute for a doctor or a medical diagnosis. Always consult a medical professional for serious symptoms or emergencies."
            },
            "YOU_CAN_2": {
                "en-US": "You can easily connect with a board-certified physician within the app after the questions."
            },
            "REVIEW_TERMS": {
                "en-US": "You are <BOLD>more than<BOLD> welcome <ITALIC>to <BOLD>review<BOLD><ITALIC> our <LINK><BOLD>terms<BOLD> of <ITALIC>use<ITALIC><LINK> at <BOLD>any<BOLD> time."
            },
            "I_DO": {
                "en-US": "I do not agree"
            },
            "I_AGREE": {
                "en-US": "I agree"
            }
        },
        body: [
            "THIS_ASSESSMENT_0",
            "THIS_ASSESSMENT_1",
            "YOU_CAN_2"
        ]
    }
}

const entryNodeTemplate: FlowNode = {
    id: 1,
    data: botInputNode,
    inputs: { "act": { "connections": [] } },
    outputs: {
        "1_0": { "connections": [] },
        "1_1": { "connections": [] },
        "1_2": { "connections": [] }
    },
    position: [0, 0],
    name: "BotInput"
}
