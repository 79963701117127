import React from "react";
import styled from "styled-components";

// MARK: Constants

const DefaultWidth = 45;

// MARK: Types

// eslint-disable-next-line @typescript-eslint/no-namespace
declare module TextAction {
    export type Props = {
        value: string;
        width: number;
    };
    
    export type State = {
        value?: string;
        width: number;
    };
}

// MARK: Styles

const Styled = {
    Container: styled.div`
    position: relative;
    display: inline-flex;
    margin-bottom: 20px;
    padding: 0;
  `,
    TextAction: styled.button`
    align-self: flex-end;
    background: #ffffff;
    background-color: transparent;
    border-width: 0;
    border-style: none;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
    display: inline-block;
    font-size: 17px;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    flex-direction: row;
    letter-spacing: normal;
    margin: 0px 20px;
    padding: 6px 6px;
    text-align: left;
    text-decoration: none;
    text-indent: 0px;
    text-rendering: auto;
    text-shadow: none;
    text-transform: none;
    word-spacing: normal;
    -webkit-appearance: button;
    -webkit-box-pack: justify;
  `,
    ActionTitle: styled.span`
    text-transform: none;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87);
    line-height: 18px;
  `,
};

// MARK: Component

export class TextActionControl extends React.Component<TextAction.Props, TextAction.State> {
    mounted: boolean = false;
    state = {
        width: DefaultWidth,
        value: "",
    };
    
    constructor(props: TextAction.Props) {
        super(props);
        
        this.state = {
            width: DefaultWidth,
            value: props.value,
        };
        
        this.setValue = this.setValue.bind(this);
    }
    
    componentDidMount() {
        this.mounted = true;
    }
    
    setValue(event: { target: { value: any } }) {
        const { value } = event.target;
        this.setState({ value });
    }
    
    actionButton() {
        const { value } = this.state;
        
        return (
            <Styled.TextAction>
                <Styled.ActionTitle>{value}</Styled.ActionTitle>
            </Styled.TextAction>
        );
    }
    
    render() {
        return <Styled.Container>{this.actionButton()}</Styled.Container>;
    }
}
