import React from "react";
import styled from "styled-components";

// MARK: Constants

const FontSize = 24;
const MinHeight = FontSize + 16;
const BottomPadding = 0;
const DefaultHeight = MinHeight * 2;

// MARK: Types

interface Props {
    id: string;
    value: string;
    height: number;
}

interface State {
    id: string;
    value: string;
    height: number;
}

// MARK: Styles

const Styled = {
    ExpandingTextarea: styled.textarea`
        background: transparent;
        border-style: none;
        box-shadow: none;
        display: flex;
        flex-flow: column;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        font-family: "Roboto", sans-serif;
        font-size: ${FontSize}px;
        font-weight: lighter;
        margin: 0;
        min-height: ${MinHeight}px;
        outline: none;
        overflow: hidden;
        padding: 0;
        resize: none;
        text-align: left;
        transition: height 0.2s ease;
        width: 100%;
    `,
    GhostArea: styled.div`
        border-style: none;
        box-shadow: none;
        display: block;
        font-family: "Roboto", sans-serif;
        font-size: ${FontSize + BottomPadding}px;
        font-weight: lighter;
        margin: 0;
        min-height: ${MinHeight}px;
        opacity: 0.3;
        outline: none;
        overflow: hidden;
        padding: 0;
        position: absolute;
        text-align: left;
        top: 0;
        visibility: hidden;
        white-space: pre-wrap;
        width: 100%;
        word-wrap: break-word;
    `,
    TextContainer: styled.div`
        position: relative;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        
        &.disabled {
            pointer-events: none; 
            -webkit-user-select: none; 
            -moz-user-select: none;
            -ms-user-select: none; 
            user-select: none;
            
            :focus { 
                outline: 0; 
            }
        }
    `,
};

// MARK: Component

export class BotTextarea extends React.Component<Props, State> {
    mounted: boolean = false;
    ghost: any;
    state: State = {
        id: "",
        value: "",
        height: DefaultHeight
    };
    
    constructor(props: Props) {
        super(props);
        
        this.state = {
            id: props.id,
            value: props.value,
            height: DefaultHeight
        };
        
        this.setValue = this.setValue.bind(this);
        this.setHeight = this.setHeight.bind(this);
    }
    
    componentDidMount() {
        this.mounted = true;
        window.setTimeout(() => this.setHeight(), 1);
    }
    
    componentWillReceiveProps(props: Props) {
        this.setState({
            id: props.id,
            value: props.value
        });
    }
    
    setHeight() {
        if (!this.mounted) return;
        if (!this.ghost) return;
        
        this.setState({ height: this.ghost.clientHeight });
    }
    
    setValue(event: { target: { value: any } }) {
        const { value } = event.target;
        this.setState({ value });
    }
    
    expandableField() {
        const { height, value } = this.state;
        
        /*let text: string = value;
        (link || []).forEach(l => {
            const linkedSubstring: string = `<a href="${l.target.url}"` +
            l.target.openInNewWindow ? ` target="_blank">` : `>` +
                l.substring + `</a>`;
            text.replace(l.substring, linkedSubstring);
        });*/
        
        return (
            <Styled.ExpandingTextarea
                key={"bot-textarea-" + this.state.value}
                defaultValue={value}
                style={{
                    height,
                    resize: "none",
                }}
                onKeyUp={this.setHeight}
            >
            
            </Styled.ExpandingTextarea>
        );
    }
    
    ghostField() {
        return (
            <Styled.GhostArea
                ref={(c) => (this.ghost = c)}
                defaultValue={this.state.value}
                aria-hidden="true"
                key={"bot-textarea-ghost" + this.state.value}
            >
                {this.state.value}
            </Styled.GhostArea>
        );
    }
    
    render() {
        return (
            <Styled.TextContainer className={"disabled"} key={"bot-textarea-container-" + this.state.value}>
                {this.expandableField()}
                {this.ghostField()}
            </Styled.TextContainer>
        );
    }
}
