// @ts-ignore
import React from "react";
// @ts-ignore
import styled from "styled-components";
import { ListViewComponent } from "@syncfusion/ej2-react-lists";
import { Choice } from "../models/Action";

// MARK: Types

interface Props {
    values: Choice[];
}

interface State {
    values: Choice[];
}

// @ts-ignore
const Styled = {
    Container: styled.div`
        position: relative;
        display: inline-flex;
        margin-bottom: 10px;
        padding: 0;
    `,
    List: styled(ListViewComponent)`
        display: block;
        max-width: 400px;
        margin: auto;
        border: 1px solid #dddddd;
        border-radius: 3px;
    `,
};

// MARK: Component

export class TableControl extends React.Component<Props, State> {
    mounted: boolean = false;
    fields = { text: "text", id: "id" };
    state = {
        values: [] as Choice[]
    };
    
    constructor(props: Props) {
        super(props);
        
        this.state = {
            values: props.values
        };
        
        this.setValue = this.setValue.bind(this);
    }
    
    componentDidMount() {
        this.mounted = true;
    }
    
    setValue(event: { target: { value: any } }) {
        const { value } = event.target;
        this.setState({ values: value });
    }
    
    data() {
        return this.state.values.map((value, index) => {
            return { text: value.text, id: value.value }
        });
    }
    
    render() {
        return (
            <Styled.Container>
                <Styled.List
                    dataSource={this.data()}
                    fields={this.fields}
                    showCheckBox={false}
                    checkBoxPosition={"Right"}/>
            </Styled.Container>
        );
    }
}
