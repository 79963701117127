import React from "react";
import styled from "styled-components";
import { Control, Node, Socket } from "rete-react-render-plugin";
import * as Rete from "rete";
import { outputTitleForAction } from "../helpers/ActionHelpers";
import { EnumOptionControl } from "../views/EnumOption";
import { PrimaryActionControl } from "../views/PrimaryAction";
import { SecondaryActionControl } from "../views/SecondaryAction";
import { TableControl } from "../views/TableAction";
import { TextActionControl } from "../views/TextAction";
import { MultiselectControl } from "../views/MultiselectAction";
import { ReactBodyControl } from "./controls/BodyControl";
import {
    BotInputNodeDataPayload,
    FlowNode,
    FlowNodeDataPayload,
    FlowNodeType
} from "../models/Node";
import {FreeResponseAction, MultiselectAction, PrimaryAction, TableAction, TableRow, FeatureRow, LineItemRow, TextAction} from "../models/Action";
import {actionSocket} from "../Constants";

const Styled = {
    Node: styled.div`
        background: #ffffff;
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        flex-flow: column;
        font-family: "Roboto", sans-serif;
        width: 475px;
        min-height: 600px;
        padding-bottom: 30px;
        padding-top: 10px;
        
        &.selected {
            background: #ffffff;
        }
        
        &.router {
            min-height: 135px;
            border-color: #444444;
            border-width: 3px;
        }
        
        :hover {
            background: #f7f7f7;;
        }
        
        &.node input {
          border-radius: 28px;
          border-width: 2px;
          border-style: none;
          border-color: rgb(0, 177, 169);
          color: rgba(0, 0, 0, 0.87);
          cursor: pointer;
          font-family: "Roboto", sans-serif;
          font-size: 20px;
          font-weight: normal;
          letter-spacing: normal;
          margin: 3px 0;
          padding: 10px 14px;
          text-indent: 0px;
          text-rendering: auto;
          text-shadow: none;
          text-transform: none;
          word-spacing: normal;
        }
        
        &.node .input {
          padding: 0;
          margin-right: 4px;
          margin-top: 2px;
        }
        
        &.node .input-title {
          color: rgba(0, 0, 0, 0.54);
          font-size: 19px;
          font-weight: normal;
          font-family: "Roboto", sans-serif;
          margin-left: 2px;
          margin-top: 4px;
        }
        
        &.node .output {
          color: rgba(0, 0, 0, 0.54);
          font-family: "Roboto", sans-serif;
        }
        
        &.node .output-title {
            color: rgba(0, 0, 0, 0.54);
            font-family: "Roboto", sans-serif;
        }
        
        &.node .actions {
          flex-grow: 0;
          flex-shrink: 1;
          flex-basis: auto;
          margin-top: 50px;
        }
        
        &.node .connection {
          opacity: 1;
        }
        
        &.node .output .sockets {
          opacity: 1;
        }
        
        &.node .output .sockets .socket {
          opacity: 1;
        }
        
        &.node .output .external {
          background: rgb(124, 168, 219);
        }
        
        &.node .output .actions {
          margin-right: 20px;
          margin-bottom: -54px;
          margin-top: 21px;
        }
    `,
    Spacer: styled.div`
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
    `,
    Input: styled.div`
      border-radius: 28px;
      border-width: 2px;
      border-style: none;
      border-color: rgb(0, 177, 169);
      color: rgba(0, 0, 0, 0.87);
      cursor: pointer;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-weight: normal;
      letter-spacing: normal;
      margin: 3px 0;
      padding: 10px 14px;
      text-indent: 0px;
      text-rendering: auto;
      text-shadow: none;
      text-transform: none;
      word-spacing: normal;
    `,
    InputTitle: styled.div`
        color: rgba(0, 0, 0, 0.54);
        font-size: 19px;
        font-weight: normal;
        font-family: "Roboto", sans-serif;
        margin-left: 4px;
        margin-top: 8px;
    `
};

export class ReactNode extends Node {
    render() {
        const { node, bindSocket, bindControl } = this.props;
        const { inputs, outputs, controls, selected } = this.state;
        const nodeType: FlowNodeType = (node as FlowNode).name;
        
        return (
            <Styled.Node className={`node ${selected} ${(nodeType !== "BotInput") && "router"}`}>
                {/* Inputs */}
                {inputs.map(
                    (input: {
                        key: string | number | undefined;
                        socket: any;
                        showControl: () => any;
                        control: any;
                    }, index: number) => (
                        <Styled.Input className="input" key={input.key}>
                            <Socket
                                type="input"
                                socket={input.socket}
                                io={input}
                                innerRef={bindSocket}
                            />
                            {!input.showControl() && (
                                <Styled.InputTitle className="input-title">{
                                    index === 0 ? `${node.data.label}` : `${input.key}`.toUpperCase()
                                }</Styled.InputTitle>
                            )}
                            {input.showControl() && (
                                <Control
                                    className="input-control"
                                    control={input.control}
                                    innerRef={bindControl}
                                />
                            )}
                        </Styled.Input>
                    )
                )}
                {/* Controls */}
                {controls.map((control: { key: string | number | undefined }) => (
                    <Control
                        className="control"
                        key={control.key}
                        control={control}
                        innerRef={bindControl}
                    />
                ))}
                {/* Spacer */}
                {(nodeType === ("BotInput" as FlowNodeType)) && (<Styled.Spacer className="spacer" key={`spacer`}/>)}
                {/* Outputs */}
                <div className="actions" key={`node-actions`}>
                    {outputs.map(
                        (output: { key: string | number | undefined; socket: any }) => (
                            <div className="output" key={output.key}>
                                <div className="output actions">
                                    {this.generateActions(node, output.key)}
                                </div>
                                <div className="output sockets">
                                    {(nodeType === ("Result" as FlowNodeType)) ? <></> :
                                        <Socket
                                            type={"output"}
                                            socket={output.socket}
                                            io={output}
                                            innerRef={bindSocket}
                                        />
                                    }
                                </div>
                            </div>
                        )
                    )}
                </div>
            </Styled.Node>
        );
    }
    
    generateActions(node: any, id: string | number | undefined) {
        const flowNode = node as FlowNode;
        const data = flowNode.data.payload as FlowNodeDataPayload;
        const action = data.actions.find(function (act: { id: any }) {
            return act.id === id;
        });
        
        if (!action) {
            console.log("No Action Found!: " + id);
            return {};
        }
        
        if (!node.outputs.get(action.id)) {
            node.addOutput(
                new Rete.Output(action.id, action.payload, actionSocket, false)
            );
            
            node.update();
        }

        const botInputNodePayload: BotInputNodeDataPayload | undefined = data as BotInputNodeDataPayload
        const localizations = botInputNodePayload.localizations || {};
        const title: string = outputTitleForAction(action, localizations);
        
        switch (action.type) {
            case "ENUM":
                return (
                    <EnumOptionControl
                        key={id}
                        value={title}
                        width={0}
                    />
                );
            case "FREE_RESPONSE":
                return (
                    <PrimaryActionControl
                        key={id}
                        value={title}
                        icon={(action.payload as FreeResponseAction.Payload)?.submitIcon || null}
                        width={0}
                    />
                );
            case "PRIMARY":
                return (
                    <PrimaryActionControl
                        key={action.id}
                        value={title}
                        icon={(action.payload as PrimaryAction.Payload)?.icon || "ARROW" }
                        width={0}
                    />
                );
            case "SECONDARY":
                return (
                    <SecondaryActionControl
                        key={action.id}
                        value={title}
                        width={0}
                    />
                );
            case "TEXT":
                var display = "TextAction";
                const key = (action.payload as TextAction.Payload)?.text
                if (key) {
                    if (Object.keys(localizations[key])) {
                        display = localizations[key]["en-US"];
                    }
                }

                return (
                    <TextActionControl
                        key={action.id}
                        value={display}
                        width={0}
                    />
                )
            case "TABLE":
                const rows = (action.payload as TableAction.Payload).table.rows;
                return (
                    <TableControl
                        key={action.id}
                        values={rows.map((row: TableRow) => {
                            var text = "row";
                            if (row.type === "FEATURE") {
                                text = (row as FeatureRow).title;
                            } else {
                                text = (row as LineItemRow).text;
                            }

                            return { text: localizations[text]["en-US"], value: text }
                        })}
                    />
                );
            case "MULTI_SELECT":
                var payload = action.payload as MultiselectAction.Payload;
                if (!payload) throw new Error("Could not case payload to Multiselect type: " + action.payload);

                const result = (!payload.noSelection) ? (
                    <MultiselectControl
                        key={action.id}
                        values={payload.choices.map((choice: { text: any; value: any; }) => {
                            return { text: localizations[choice.text]["en-US"], value: choice.value }
                        })}
                    />
                ) : (
                <>
                    <MultiselectControl
                        key={action.id}
                        values={payload.choices.map((choice: { text: any; value: any; }) => {
                            return { text: localizations[choice.text]["en-US"], value: choice.value }
                        })}
                    />
                    <Styled.Spacer></Styled.Spacer>
                    <SecondaryActionControl
                        key={action.id + "_NO_SELECTION"}
                        value={localizations[payload.noSelection.text]["en-US"]}
                        width={0}
                    />
                </>
                )

                return result;
            case "RULESET":
                return (
                    <SecondaryActionControl
                        key={action.id}
                        value={title}
                        width={0}
                    />
                );
            case "RESULT":
                return (
                    <SecondaryActionControl
                        key={action.id}
                        value={action.payload.name}
                        width={0}
                    />
                );
            case "EXIT_NAVIGATION":
                return (
                    <SecondaryActionControl
                        key={action.id}
                        value={action.payload.name}
                        width={0}
                    />
                );
            case "BACK_NAVIGATION":
                return (
                    <SecondaryActionControl
                        key={action.id}
                        value={action.payload.name}
                        width={0}
                    />
                );
            case "CLOSE_MODAL_NAVIGATION":
                return (
                    <SecondaryActionControl
                        key={action.id}
                        value={action.payload.name}
                        width={0}
                    />
                );
            default:
               return (
                   <ReactBodyControl
                        id={`${action.type}-${id}`}
                        body={`\n-------------------------------------------------------------\n${action.type.charAt(0).toUpperCase() + action.type.toLowerCase().slice(1)}Action: ${JSON.stringify(action.payload, null, "\t")}\n-------------------------------------------------------------\n`}
                   />
               );
        }
    }
}
