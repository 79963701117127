import React from "react";
import { nodeFor } from "../Editor";
import { getUpdatedPayload } from "../helpers/ActionHelpers";
import { Styled } from "./Inspector"
import {FlowNode, ResultNodeData} from "../models/Node";
import {Action, ResultAction} from "../models/Action";

interface Props {
    id: number;
}

export class ResultInspectorPanel extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.setValue = this.setValue.bind(this);
    }
    
    componentWillReceiveProps(props: Props) {
        this.setState(props);
    }
    
    render(): React.ReactNode {
        const node = nodeFor(this.props.id);
        if (!node) throw new Error();
        const flowNode: FlowNode = node as unknown as FlowNode;
        if (!flowNode) throw new Error();
        let data: ResultNodeData = flowNode.data as unknown as ResultNodeData;
        if (!data) throw new Error();
        
        const label = flowNode.data.label;
        const actions = data.payload.actions;
        
        return (
            <Styled.PropertyList key="property-list">
                <Styled.Subheading key="name-label">NAME</Styled.Subheading>
                <Styled.Input
                    id="label"
                    key="label"
                    value={label}
                    onChange={this.setValue}
                />
                {this.generateResult(actions)}
            </Styled.PropertyList>
        );
    }
    
    generateResult(actionElements: Action[]) {
        return [
            /*
            <Styled.Subheading key="result-label">RESULT</Styled.Subheading>,
            actionElements.map((action, index) => {
                const payload = action.payload as ResultAction.Payload;

                return <Styled.InputDiv id={"div-result-" + index}
                                 key={"div-result-" + index}>
                    <Styled.Input
                        id={"result-" + index}
                        key={"prop-node-result-" + index}
                        value={payload.name}
                        onChange={this.setValue}
                    />
                </Styled.InputDiv>
            })*/
        ];
    }
    
    async setValue(event: { target: { value: any; id: any } }) {
        const { value, id } = event.target;

        const node = nodeFor(this.props.id);
        if (!node) throw new Error("ERROR! No node with id: " + this.props.id);
        const flowNode: FlowNode = node as unknown as FlowNode;
        if (!flowNode) throw new Error();
        let data: ResultNodeData = flowNode.data as unknown as ResultNodeData;
        if (!data) throw new Error();
        
        if (id === "label") {
            flowNode.data.label = value;
            flowNode.data.payload.actions[0].payload.name = value;
            node.data = flowNode.data as unknown as { [key: string]: unknown };

            await node.update();
            this.setState({ label: value });
        } else if (id.startsWith("actions-")) {
            const actionId = id.split("-")[1] as number;
            const updatedPayload = getUpdatedPayload(
                data.payload.actions[actionId].type,
                data.payload.actions[actionId].payload!,
                value
            );
            data.payload.actions[actionId].payload = updatedPayload[0] as ResultAction.Payload;
            flowNode.data = data;
            node.data = flowNode.data as unknown as { [key: string]: unknown };
            await node.update();
            this.setState({ actions: data.payload.actions });
        } else {
            console.log("[Unknown][" + id + "] " + value);
        }
    }
}