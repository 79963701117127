import React from "react";
import {Icon} from "../views/Icon";
import {nodeFor} from "../Editor";
import Dropdown from "react-dropdown";
import {outputTitleIdForAction} from "../helpers/ActionHelpers";
import {Styled} from "./Inspector"
import {BodyControl} from "../nodes/controls/BodyControl";
import {Action} from "../models/Action";
import {BotInputNodeData, FlowNode} from "../models/Node";
import {Localizations} from "../models/Localization";
import {
    actionsForNode,
    addActionToNode,
    addBotMessageToNode,
    botMessagesForNode,
    getLocalizedString,
    removeActionFromNode,
    removeBodyFromNode,
    setActionTitle
} from "../Adapters";

export enum HeaderItem {
    BACK_NAVIGATION = "BACK_NAVIGATION",
    EXIT_NAVIGATION = "EXIT_NAVIGATION"
}

interface Props {
    nodeId: number;
    actions: Action[];
    header: HeaderItem[];
    body: string[];
    label: string;
    localizations: Localizations;
}

interface State {
    nodeId: number;
    actions: Action[];
    header: HeaderItem[];
    body: string[];
    label: string;
    localizations: Localizations;
}

const ActionOptions = ["Primary Action", "Secondary Action", "Enum Action", "Free Response", "Multiple Selection"];
const DefaultActionOption = ActionOptions[0];
let currentActionOption = ActionOptions[0];

export class BotInputInspectorPanel extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        
        this.state = {
            nodeId: props.nodeId,
            actions: props.actions,
            header: props.header,
            body: props.body,
            label: props.label,
            localizations: props.localizations
        };
        
        this.setValue = this.setValue.bind(this);
    }
    
    componentWillReceiveProps(props: Props) {
        this.setState(props);
    }
    
    render() {
        const node = nodeFor(this.state.nodeId); if (!node) throw new Error("No node with ID: " + this.state.nodeId);
        const botInputNodeData = node.data as BotInputNodeData;

        return (
            <Styled.PropertyList key={"bot-input-property-list-" + this.state.nodeId}>
                <Styled.Subheading key={"name-label-" + this.state.nodeId}>NAME</Styled.Subheading>
                <Styled.Input
                    id="label"
                    key={"label-" + this.state.nodeId}
                    value={botInputNodeData.label}
                    onChange={this.setValue}
                />
                {this.generateBody()}
                {this.generateActions()}
            </Styled.PropertyList>
        );
    }
    
    generateHeader(header: HeaderItem[]) {
        const backNavigationIcon: string = header.includes(HeaderItem.BACK_NAVIGATION)
            ? `CHECK-ON`
            : `CHECK-OFF`;
        const exitNavigationIcon: string = header.includes(HeaderItem.EXIT_NAVIGATION)
            ? `CHECK-ON`
            : `CHECK-OFF`;
        
        return [
            <Styled.Subheading id={"header-items-label"}
                               key={"header-items-label-" + this.state.nodeId}>HEADER</Styled.Subheading>,
            <>
                {(<Styled.CheckboxDiv
                    id={"back-navigation"}
                    key={"back-navigation-" + this.state.nodeId}
                    onClick={async () => {
                        await this.toggleHeader(HeaderItem.BACK_NAVIGATION);
                    }}
                >
                    <Icon
                        graphic={backNavigationIcon}
                        key={"back-navigation-icon-" + this.state.nodeId + "-" + backNavigationIcon}
                    />
                    <Styled.CheckboxLabel>Back navigation</Styled.CheckboxLabel>
                </Styled.CheckboxDiv>)}
                <Styled.CheckboxDiv
                    id={"exit-navigation"}
                    key={"exit-navigation-" + this.state.nodeId}
                    onClick={async () => {
                        await this.toggleHeader(HeaderItem.EXIT_NAVIGATION);
                    }}
                >
                    <Icon
                        graphic={exitNavigationIcon}
                        key={"exit-navigation-icon-" + this.state.nodeId + "-" + exitNavigationIcon}
                    />
                    <Styled.CheckboxLabel>Exit navigation</Styled.CheckboxLabel>
                </Styled.CheckboxDiv></>
        ];
    }
    
    /*
    generateProperties(properties: string[]) {
        const entryPointIcon: string = properties.includes(Property.ENTRY_POINT)
            ? `CHECK-ON`
            : `CHECK-OFF`;
        const logActionsIcon: string = properties.includes(Property.LOG_ACTIONS)
            ? `CHECK-ON`
            : `CHECK-OFF`;
        
        return [
            <Styled.Subheading id={"properties-label"}
                               key={"properties-label-" + this.state.nodeId}>PROPERTIES</Styled.Subheading>,
            (<><Styled.CheckboxDiv
                id={"entry-point"}
                className={"disabled"}
                key={"entry-point-" + this.state.nodeId}
                onClick={async () => {
                    await this.toggleProperty(Property.ENTRY_POINT);
                }}
            >
                <Icon
                    graphic={entryPointIcon}
                    key={"entry-point-icon-" + this.state.nodeId + "-" + entryPointIcon}
                />
                <Styled.CheckboxLabel className={"disabled"}>Entry point</Styled.CheckboxLabel>
            </Styled.CheckboxDiv>
                <Styled.CheckboxDiv
                    id={"log-actions"}
                    key={"log-actions-" + this.state.nodeId}
                    onClick={async () => {
                        await this.toggleProperty(Property.LOG_ACTIONS);
                    }}
                >
                    <Icon
                        graphic={logActionsIcon}
                        key={"log-actions-icon-" + this.state.nodeId + "-" + logActionsIcon}
                    />
                    <Styled.CheckboxLabel>Log actions</Styled.CheckboxLabel>
                </Styled.CheckboxDiv></>)
        ];
    }
    */
    
    generateActions() {
        return [
            <Styled.Subheading key={"actions-label-" + this.state.nodeId}>ACTIONS</Styled.Subheading>,
            actionsForNode(this.state.nodeId).map((action, index) => {
                let id = outputTitleIdForAction(action);
                const value = getLocalizedString(this.state.nodeId, id);
                
                return (
                    <Styled.InputDiv
                        id={"div-action-" + index}
                        key={"div-action-" + this.state.nodeId + "-" + index}
                    >
                        <Styled.Input
                            id={"actions-" + index}
                            key={"prop-node-action-" + this.state.nodeId + "-" + index}
                            value={value}
                            onChange={this.setValue}
                        />
                        <Styled.Row
                            id={"action-row-" + index}
                            key={"prop-node-action-row-" + this.state.nodeId + "-" + index}
                            onClick={async () => {
                                const actions = await removeActionFromNode(this.state.nodeId, index);
                                this.setState({ actions: actions });
                            }}
                        >
                            <Icon key={"close-action-row-" + this.state.nodeId + "-" + index} graphic="CLOSE"/>
                        </Styled.Row>
                    </Styled.InputDiv>
                );
            }),
            <Styled.AddDiv key={"add-action-div-" + this.state.nodeId}>
                <Dropdown
                    key={"add-action-div-dropdown-" + this.state.nodeId}
                    className="dropdown-menu-item"
                    controlClassName="dropdown-menu"
                    placeholderClassName="dropdown-menu-item"
                    menuClassName="dropdown-menu-items-container"
                    options={ActionOptions}
                    onChange={this.onSelectActionType}
                    value={DefaultActionOption}
                    placeholder="Add action element"
                />
                <Styled.Row
                    key={"add-action-div-row-" + this.state.nodeId}
                    onClick={async () => {
                        const data = await addActionToNode(this.state.nodeId, currentActionOption);
                        this.setState({ actions: data.payload.actions });
                    }}
                >
                    <Icon graphic="ADD" key={"add-action-icon-" + this.state.nodeId}/>
                </Styled.Row>
            </Styled.AddDiv>,
        ];
    }

    generateBody() {
        return [
            <Styled.Subheading key={"body-label-" + this.state.nodeId}>BODY</Styled.Subheading>,
            botMessagesForNode(this.state.nodeId).map((message, index) => {
                return (
                    <Styled.InputDiv id={"div-body-" + index} key={"div-body-" + this.state.nodeId + "-" + index}>
                        <Styled.Input
                            id={"body-" + index}
                            key={"prop-node-body-" + index}
                            value={message}
                            onChange={this.setValue}
                        />
                        <Styled.Row
                            id={"body-row-" + index}
                            key={"prop-node-body-row-" + this.state.nodeId + "-" + index}
                            onClick={async () => {
                                const data = await removeBodyFromNode(this.state.nodeId, index);
                                this.setState({ body: data.payload.body });
                            }}
                        >
                            <Icon key={"close-body-row-" + index} graphic="CLOSE"/>
                        </Styled.Row>
                    </Styled.InputDiv>
                );
            }),
            <Styled.AddDiv key={"add-body-div-" + this.state.nodeId}>
                <Dropdown
                    key={"add-body-div-dropdown-" + this.state.nodeId}
                    className="dropdown-menu-item"
                    controlClassName="dropdown-menu"
                    placeholderClassName="dropdown-menu-item"
                    menuClassName="dropdown-menu-items-container"
                    options={["Bot Message"]}
                    onChange={this.onSelectBodyType}
                    value={"Bot Message"}
                    placeholder={"Bot Message"}
                />
                <Styled.Row
                    key={"add-body-div-row-" + this.state.nodeId}
                    onClick={async () => {
                        const data = await addBotMessageToNode(this.state.nodeId, `A message from the bot.`);
                        this.setState({ body: data.payload.body });
                    }}
                >
                    <Icon key={"add-body-icon-" + this.state.nodeId} graphic="ADD"/>
                </Styled.Row>
            </Styled.AddDiv>,
        ];
    }
    
    onSelectActionType(event: { value: string }) {
        currentActionOption = event.value;
    }
    
    onSelectBodyType(event: { value: string }) {}
    
    async toggleHeader(item: HeaderItem) {
        const node = nodeFor(this.state.nodeId);
        if (!node) throw new Error("ERROR! No node with id: " + this.state.nodeId);
        const flowNode: FlowNode = node as unknown as FlowNode;
        if (!flowNode) throw new Error();
        let data: BotInputNodeData = flowNode.data as unknown as BotInputNodeData;
        if (!data) throw new Error();

        /* let header = data.header;
        
        if (header.includes(item)) {
            header = header.filter((p) => p !== item);
        } else {
            header.push(item);
        }
        
        data.header = header;
        flowNode.payload = data;
        node.data = flowNode.data as unknown as { [key: string]: unknown };
        await node.update();
        this.setState({ header: header });*/
    }
    
    async setValue(event: { target: { value: any; id: any } }) {
        const { value, id } = event.target;
        const node = nodeFor(this.state.nodeId);
        if (!node) throw new Error("ERROR! No node with id: " + this.state.nodeId);
        let data = node.data as BotInputNodeData;
        if (!data) throw new Error();
        
        if (id === "label") {
            node.data.label = value;
            await node.update()
            this.setState({ label: value });
        } else if (id.startsWith("body-")) {
            const bodyId = id.split("-")[1] as number;
            const controlId = `${node.id}-BODY-${bodyId}`;

            data.payload.localizations[data.payload.body[bodyId]]["en-US"] = value;
            node.data = data;

            const control = node.controls.get(controlId) as unknown as BodyControl;
            await control.update();
            await node.update();
            this.setState({ body: (nodeFor(this.state.nodeId)!.data as BotInputNodeData).payload.body });
        } else if (id.startsWith("actions-")) {
            const actionId = id.split("-")[1] as number;
            await setActionTitle(this.state.nodeId, actionId, value);
            this.setState({ actions: (nodeFor(this.state.nodeId)!.data as BotInputNodeData).payload.actions });
        } else {
            console.log("[Unknown][" + id + "] " + value);
        }
    }
}
