import { loader } from "../App";

export function startLoading() {
    if (loader.state.isLoading) {
        stopLoading();
        setTimeout(200, () => {
            loader.setState({ isLoading: true });
        })
    } else {
        loader.setState({ isLoading: true });
    }
}

export function stopLoading(withDelay?: number) {
    if (loader.state.isLoading) {
        if (withDelay) {
            window.setTimeout(() => {
                loader.setState({ isLoading: false });
            }, withDelay);
        } else {
            loader.setState({ isLoading: false });
        }
    }
}
