import * as Rete from "rete";
import ReactRenderPlugin from "rete-react-render-plugin";
import AreaPlugin from "rete-area-plugin";
import AutoArrangePlugin from "rete-auto-arrange-plugin";
import ConnectionMasteryPlugin from "rete-connection-mastery-plugin";
import ConnectionPathPlugin from "rete-connection-path-plugin";
import ConnectionPlugin from "rete-connection-plugin";
import ContextMenuPlugin from 'rete-context-menu-plugin';
import MiniMapPlugin from "rete-minimap-plugin";
import * as Constants from "./Constants";
import {BotInputNodeComponent} from "./nodes/BotInputNodeComponent";
import {RouterNodeComponent} from "./nodes/RouterNodeComponent";
import {ReactNode} from "./nodes/ReactNode";
import {codeView, getStoredUser, header, inspector, iphoneView, setUser, userName} from "./App";
import {NodeEditor} from "rete/types/editor";
import {Engine} from "rete/types/engine";
import {getFlow, getStoredFlow, postFlow, refreshStore} from "./store/Store";
import {Node} from "rete/types/node";
import {inputPrompt} from "./helpers/PromptHelper";
import {startLoading, stopLoading} from "./helpers/LoaderHelper";
import {emptyFlow} from "./Defaults";
import {ResultNodeComponent} from "./nodes/ResultNodeComponent";
import {WebModalNodeComponent} from "./nodes/WebModalNodeComponent";
import {NetworkNodeComponent} from "./nodes/NetworkNode";
import {CustomModalNodeComponent} from "./nodes/CustomModalNodeComponent";
import {Data} from "rete/types/core/data";
import {toFlowFromFlowWriterFlow, toFlowWriterFlow} from "./Adapters";
import {BotInputNodeData, FlowNode, RouterNodeData} from "./models/Node";
import {Flow} from "./models/Flow";
import {DEFAULT_FLOW} from "./Constants";

export let editor: NodeEditor;
export let engine: Engine;
export var userEmail: string;

let currentFlow = emptyFlow("UNTITLED");
let timer: number | null;

async function autoSync(repeat: boolean = true) {
    if (timer) clearTimeout(timer);
    
    if (currentFlow.id.length < 1) {
        timer = setTimeout(autoSync, 2500, true);
        return;
    }

    let storedFlow = getStoredFlow(currentFlow.id);

    if ((storedFlow) && (storedFlow.updated > currentFlow.updated)) {
        await engine.abort();
        await engine.process(storedFlow as any);
    }

    stopLoading(100);
    
    if (repeat) {
        timer = setTimeout(autoSync, 7000, true);
    }
}

// MARK: Editor

export default async function (container: HTMLDivElement) {
    editor = new Rete.NodeEditor(Constants.FLOWSCHEMA_VERSION, container);
    engine = new Rete.Engine(Constants.FLOWSCHEMA_VERSION);
    const components = [
        new BotInputNodeComponent(),
        new RouterNodeComponent(),
        new ResultNodeComponent(),
        new WebModalNodeComponent(),
        new NetworkNodeComponent(),
        new CustomModalNodeComponent()
    ];
    
    installPlugins();
    registerComponents();
    attachObservers();
    initializeFlow().then();
    
    function installPlugins() {
        editor.use(ReactRenderPlugin, { component: ReactNode });
        editor.use(AreaPlugin, { snap: true, });
        editor.use(AutoArrangePlugin, {
            margin: { x: 150, y: 150 },
            depth: 100,
        });
        editor.use(ConnectionPlugin, { curvature: 0.15 });
        editor.use(ConnectionMasteryPlugin);
        editor.use(ConnectionPathPlugin, {
            type: ConnectionPathPlugin.DEFAULT,
            curve: ConnectionPathPlugin.curveBundle,
            options: { vertical: false, curvature: 0.15 },
            arrow: true,
        });
        editor.use(ContextMenuPlugin, {
            searchBar: true,
            searchKeep: (title: any) => true,
            delay: 100,
            rename(component: { name: string; }) { return component.name; },
            items: {
                "Auto arrange"() {
                    editor.trigger("arrange" as any, {});
                    AreaPlugin.zoomAt(editor);
                },
                "Copy current flow"() {
                    startLoading();
                    copyCurrentFlow().then(() => {
                        stopLoading(250);
                    });
                }
            },
            allocate() {return ["Nodes"];},
            nodeItems: {
                Delete: true,
                Clone: true,
            },
        });
        editor.use(MiniMapPlugin, { size: "small" });
    }
    
    function registerComponents() {
        components.forEach((component) => {
            editor.register(component as any);
            engine.register(component as any);
        });
    }
    
    function attachObservers() {
        // @ts-ignore
        editor.on('process nodecreated noderemoved connectioncreated connectionremoved', async node => {
            if (editor.silent) return;
            await update();
        });
        
        editor.on("nodecreated", async (node) => {
            if (editor.silent) return;

            Rete.Node.latestId = node.id;
            if (inspector.state.isVisible && (node.id !== inspector.props.id)) {
                inspector.setState({ id: node.id, isVisible: true });
            }
        });
        
        editor.on("noderemoved", async (node) => {
            Rete.Node.latestId = currentFlow.nodes[(currentFlow.nodes.length - 1)].id;
            if (node.data.id === inspector.props.id) {
                inspector.setVisibility(false);
            }
        });
        
        editor.on("nodeselected", async (node) => {
            await selectNode(node);
        });
        
        editor.on("click", async (event) => await deselectAll());
    }
}

export async function initializeFlow() {
    getStoredUser();

    if (userName === "") {
        userEmail = await getEmail();
        const userSubstring: string = userEmail.split("@")[0].toLowerCase();
        const userNameValue = (userSubstring[0].toUpperCase() + userSubstring.slice(1)).replace(".", "_").toUpperCase();
        await setUser(userNameValue);
    }

    const userFlowId = await copyTourIfNeeded(userName);
    loadFlow(userFlowId, false).then(() => {
        autoSync(false);
        stopLoading(750);
    });
}

async function getEmail(): Promise<string> {
    let email;

    while (!email || email === 'undefined' || email === null) {
        email = await inputPrompt(
            "Please enter your 98point6 email address",
            "",
            "FlowWriter",
            "email"
        );
    }

    return email;
}

async function copyTourIfNeeded(userId: string): Promise<string> {
    let newFlowID: string = `${userId.toUpperCase().replace(".", "_")}_BASIC_FLOW`;
    await refreshStore();
    let existingFlow: Flow | null = await getStoredFlow(newFlowID);
    if (existingFlow) return newFlowID;
    
    let flow = await getFlow(DEFAULT_FLOW);
    flow.id = newFlowID;
    flow.updated = Math.round(Date.now() / 1000);
    
    await postFlow(newFlowID, flow);
    return newFlowID;
}

async function copyCurrentFlow() {
    let userInitials = await inputPrompt("Enter your initials to track your copy of this flow", "");
    if (!userInitials || userInitials.length <= 2) return;
    
    let newFlowID: string = `${currentFlow.id}_${userInitials}`;
    let existingFlow: Flow | null = await getStoredFlow(newFlowID);
    
    if (existingFlow) {
        userInitials = await inputPrompt("A copy with those initials already exists! Try again, or select your flow from the Flows panel on the left", "");
    }
    
    newFlowID = `${userInitials}_${currentFlow.id}`;
    existingFlow = await getStoredFlow(newFlowID);
    if (existingFlow) return;
    
    await update();
    
    let flow = await getCurrentFlow();
    flow.id = newFlowID;
    
    await postFlow(newFlowID, flow);
    await loadFlow(newFlowID);
}

export async function saveCurrentFlow() {
    startLoading();

    if (currentFlow.id.length < 1) {
        stopLoading();
        return;
    }

    await postFlow(currentFlow.id, await getCurrentFlow());
    await refreshStore();
    await engine.abort();
    await engine.process(getStoredFlow(currentFlow.id) as any);

    stopLoading(100);
}

async function selectNode(node: Node) {
    var nodeId = node.id;

    if (!nodeId) {
        const stringified = JSON.stringify(node);
        if (stringified.includes("\"id\":0")) {
            nodeId = 0;
        }  else {
            console.log("Attempted to select a node with undefined ID: " + JSON.stringify(node));
            return;
        }
    }
    
    await updateCodeEditor(nodeId);
    inspector.setState({ id: nodeId, isVisible: true });
}

export async function update() {
    await engine.abort();
    await engine.process(editor.toJSON());
    
    await saveCurrentFlow();
    await updateCodeEditor();
}

export async function updateNodes() {
    editor.nodes.map(async (node: { update: () => any }) => { await node.update(); });
    
    await editor.fromJSON(editor.toJSON());
    await saveCurrentFlow();
    
    editor.trigger("arrange" as any, {});
    AreaPlugin.zoomAt(editor);
}

export async function publishModel() {
    let flow: Flow = await getCurrentFlow();
    const json = JSON.stringify(flow, null, "\t");
    const data = "data:text/plain;charset=utf-8," + encodeURIComponent(json);
    const element = document.createElement("a");
    element.setAttribute("href", data);
    element.setAttribute("download", flow.id + ".json");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

export function nodeFor(id: number): Node | null {
    return (editor.nodes.find((node) => {
        return node.id === id;
    }) || null);
}

export function botInputNodeDataFor(id: number): BotInputNodeData | null {
    const node = nodeFor(id); if (!node) return null;
    const data = node.data as BotInputNodeData;
    return data;
}

export function routerNodeDataFor(id: number): RouterNodeData | null {
    const node = nodeFor(id);
    if (!node) return null;
    const flowNode = node as unknown as FlowNode;
    if (!flowNode) return null;
    const data = flowNode.data as RouterNodeData;
    if (!data) return null;
    return data;
}

export async function deselectAll() {
    editor.selected.clear();
    editor.nodes.map(async (node: { update: () => any }) => { await node.update(); });
    inspector.setVisibility(false);
    await updateCodeEditor();
}

export async function getCurrentFlow(): Promise<Flow> {
    const json = await editor.toJSON();
    const fwFlow = json as unknown as Data;
    const flow = toFlowFromFlowWriterFlow(fwFlow, currentFlow);
    currentFlow = flow;
    return flow;
}

export async function loadFlow(id: string, saveExisting: boolean = false) {
    if (currentFlow.id === id) return;

    let flow: Flow;
    let storedFlow: Flow | null = getStoredFlow(id);
    
    if (!storedFlow) {
        await refreshStore();
        storedFlow = await getStoredFlow(id);
    }
    
    if (!storedFlow) {
        console.log('Error, no flow with that id: ' + id); return;
    }

    flow = storedFlow;
    
    if (saveExisting && currentFlow.id) await saveCurrentFlow();

    currentFlow = flow;
    header.setName(flow.id);
    header.setDescription(flow.updated);
    iphoneView.setFlow(flow.id);
    
    await engine.abort();

    const fwData = toFlowWriterFlow(flow).data;

    // Rete.Node.latestId = flow.nodes[(flow.nodes.length - 1)].id;

    await engine.process(fwData);
    await editor.fromJSON(fwData);
    editor.view.resize();
    editor.trigger("arrange" as any, {});
    AreaPlugin.zoomAt(editor);
    await engine.abort();
    await engine.process(editor.toJSON());
    await update();
    window.setTimeout(() => {
        editor.trigger("arrange" as any, {});
        AreaPlugin.zoomAt(editor);
    }, 370);
}

export async function updateCodeEditor(node?: number | null) {
    let flow: Flow = await getCurrentFlow();
    let data: {};

    if (node) {
        const matchingNodes = flow.nodes.filter(n => { return n.id === node });
        if (matchingNodes.length > 0) {
            data = matchingNodes[0]
        } else {
            data = flow
        }
    } else {
        data = flow;
    }

    const code = JSON.stringify(data, null, "    ");
    codeView.setCode(code);
}
