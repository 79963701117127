import * as Constants from "./Constants";
import {Data} from "rete/types/core/data";
import {Locale, Localizations} from "./models/Localization";
import {Flow, FlowRunner} from "./models/Flow";
import {BotInputNodeData, FlowNode, FlowNodeData, FlowNodeType} from "./models/Node";
import {Variable} from "./models/Value";
import {editor, nodeFor} from "./Editor";
import {
    Action, ActionPayload,
    EnumAction,
    FreeResponseAction,
    MultiselectAction,
    PrimaryAction, RuleSetAction,
    SecondaryAction
} from "./models/Action";
import {generateDefaultAction, outputTitleForAction} from "./helpers/ActionHelpers";
import Rete from "rete";
import {actionSocket} from "./Constants";
import {BodyControl} from "./nodes/controls/BodyControl";
import {DividerControl} from "./nodes/controls/DividerControl";

export class FWFlow {
    id: string;
    name: string;
    version: number;
    updated: number;
    schema: number;
    runners: string[];
    variables: { [key: string]: Variable };
    parameters: { [key: string]: Variable };
    locales: string[];
    data: Data;

    constructor(
        id: string,
        version: number,
        updated: number,
        schema: number,
        runners: string[],
        variables: { [key: string]: Variable },
        parameters: { [key: string]: Variable },
        locales: string[],
        data: Data
    ) {
        this.id = id;
        this.name = id;
        this.version = version;
        this.updated = updated;
        this.schema = schema;
        this.runners = runners;
        this.variables = variables;
        this.parameters = parameters;
        this.locales = locales;
        this.data = data;
    }
}

export function toFlowWriterFlow(flow: Flow): FWFlow {
    return new FWFlow(
        flow.id,
        flow.version,
        flow.updated,
        flow.schema,
        flow.runners,
        flow.variables,
        flow.parameters,
        flow.locales,
        toFlowWriterNodesData(flow)
    );
}

export function toFlowWriterNodesData(flow: Flow): Data {
    if (flow.nodes.length === 0) {
        return {id: Constants.FLOWSCHEMA_VERSION, nodes: {}};
    }

    const pairs = flow.nodes
        .map(node => {
            let data = node.data;

            return {
                key: `${(node.id).toString()}`,
                val: {
                    name: node.data.type,
                    id: node.id,
                    data: data,
                    inputs: node.inputs,
                    outputs: node.outputs,
                    position: node.position
                }
            }
        });

    let data = {};
    pairs.forEach(item => { data[item.key] = item.val; });
    return { id: Constants.FLOWSCHEMA_VERSION, nodes: data };
}

export function toFlowFromFlowWriterFlow(flowWriterFlow: Data, flow: Flow): Flow {
    const nodes: FlowNode[] = Object.keys(flowWriterFlow.nodes).map(function (nodeId) {
        const node = flowWriterFlow.nodes[nodeId];

        const flowNode: FlowNode = {
            name: node.name as FlowNodeType,
            id: node.id,
            data: node.data as unknown as FlowNodeData,
            inputs: node.inputs,
            outputs: node.outputs,
            position: [node.position[0] || 0, node.position[1] || 0]
        }
        return flowNode;
    });

    return {
        id: flow.id,
        version: flow.version,
        updated: Math.round(Date.now() / 1000),
        schema: flow.schema,
        runners: flow.runners,
        variables: flow.variables,
        parameters: flow.parameters,
        locales: flow.locales,
        nodes: nodes
    }
}

export function emptyFlow(
    id: string,
    version: number,
    updated: number,
    schema: number,
    runners: FlowRunner[],
    variables: { [key: string]: Variable },
    parameters: { [key: string]: Variable },
    locales: Locale[],
    nodes: FlowNode[]
): Flow {
    return new Flow(
        id,
        version,
        updated,
        schema,
        runners,
        variables,
        parameters,
        locales,
        nodes
    )
}

export async function removeBodyFromNode(nodeId: number, index: number): Promise<BotInputNodeData> {
    const node = nodeFor(nodeId); if (!node) throw new Error(`Could not find a Node with id '${nodeId}'`);
    const data = node.data as BotInputNodeData; if (!data) throw new Error(`Could not cast data to BotInput: ${node}`);
    data.payload.body.splice(index, 1);
    node.data = data;
    await node.update();
    return node.data as BotInputNodeData
}

export async function addBotMessageToNode(nodeId: number, value: string): Promise<BotInputNodeData> {
    const node = nodeFor(nodeId); if (!node) throw new Error('Could not find Node for id: ' + nodeId);
    const data: BotInputNodeData = node.data as BotInputNodeData; if (!data) throw new Error('Could not cast node data to BotInputNodeData: ' + node.data);
    const body: string = `BODY_${data.payload.body.length}`;

    data.payload.localizations[body] = {"en-US": value};
    data.payload.body.push(body);
    node.data = data;

    const controlId = `${nodeId}-BODY-${data.payload.body.length - 1}`;
    const bodyControl = new BodyControl(
        editor,
        controlId,
        value,
        true
    );

    const dividerControl = node.controls.get(`${node.id}-DIVIDER`);
    if (dividerControl) { node.removeControl(dividerControl); }

    node.addControl(bodyControl as any);
    node.addControl(new DividerControl(editor, `${node.id}-DIVIDER`) as any);
    await node.update();
    return node.data as BotInputNodeData;
}

export function actionsForNode(nodeId: number): Action[] {
    const node = nodeFor(nodeId); if (!node) throw new Error(`Could not find a Node with id '${nodeId}'`);
    return (node.data as BotInputNodeData).payload.actions;
}

export function botMessagesForNode(nodeId: number): string[] {
    const node = nodeFor(nodeId); if (!node) throw new Error(`Could not find a Node with id '${nodeId}'`);
    const keys = (node.data as BotInputNodeData).payload.body;
    return keys.map(key => { return getLocalizedString(nodeId, key); });
}

export async function removeActionFromNode(nodeId: number, index: number): Promise<Action[]> {
    const node = nodeFor(nodeId); if (!node) throw new Error(`Could not find a Node with id '${nodeId}'`);
    const flowNode: FlowNode = node as unknown as FlowNode;
    const data = flowNode.data as BotInputNodeData;
    if (!data) return [];

    const id = data.payload.actions[index].id;

    editor.nodes.forEach(node => {
        const inputs = node.inputs.get("act");
        if (inputs) {
            inputs.connections.forEach(input => {
                if (input.output.key === id) editor.removeConnection(input);
            })
        }
    })

    const output = node.outputs.get(id);
    if (output) node.removeOutput(output);

    data.payload.actions.splice(index, 1);
    flowNode.data = data;
    node.data = flowNode.data as unknown as { [key: string]: unknown };
    await node.update();
    await editor.view.updateConnections({node})
    return (node.data as BotInputNodeData).payload.actions;
}

export async function addActionToNode(nodeId: number, type: string): Promise<BotInputNodeData> {
    const node = nodeFor(nodeId); if (!node) throw new Error('Could not find Node for id: ' + nodeId);
    const data: BotInputNodeData = node.data as BotInputNodeData; if (!data) throw new Error('Could not cast node data to BotInputNodeData: ' + node.data);
    const actionId: string = `${node.id}_${data.payload.actions.length}`;

    let result: [Action, Localizations] = generateDefaultAction(actionId, type, data.payload.localizations as Localizations);
    let action: Action = result[0];
    let localizations: Localizations = result[1];
    data.payload.localizations = localizations;
    data.payload.actions.push(action);

    node.data = data;

    if (!node.outputs.get(actionId)) {
        node.addOutput(
            new Rete.Output(actionId, outputTitleForAction(action, localizations), actionSocket, false)
        );
    }

    await node.update();
    return node.data as BotInputNodeData;
}

export function getActionTitle(nodeId: number, actionId: string): string {
    const action = actionsForNode(nodeId).filter(action => { return action.id === actionId })[0];

    let key: string

    if (action.type === "ENUM") {
        const payload = action.payload as EnumAction.Payload;
        if (!payload) { throw Error("Could not cast payload to EnumAction type! " + action.payload); }
        key = payload.text;
    } else if (action.type === "PRIMARY") {
        const payload = action.payload as PrimaryAction.Payload;
        if (!payload) { throw Error("Could not cast payload to PrimaryAction type! " + action.payload); }
        key = payload.text;
    } else if (action.type === "SECONDARY") {
        const payload = action.payload as SecondaryAction.Payload;
        if (!payload) { throw Error("Could not cast payload to SecondaryAction type! " + action.payload); }
        key = payload.text;
    } else if (action.type === "FREE_RESPONSE") {
        const payload = action.payload as FreeResponseAction.Payload;
        if (!payload) { throw Error("Could not cast payload to FreeResponse type! " + action.payload); }
        key = payload.submitText;
    } else if (action.type === "MULTI_SELECT") {
        const payload = action.payload as MultiselectAction.Payload;
        if (!payload) { throw Error("Could not cast payload to Multiselect type! " + action.payload); }
        key = payload.submitText;
    } else if (action.type === "RULESET") {
        const payload = action.payload as RuleSetAction.Payload;
        if (!payload) { throw Error("Could not cast payload to RuleSet type! " + action.payload); }
        key = payload.name;
    } else {
        const payload = action.payload as ActionPayload;
        if (!payload) { throw Error("Could not cast payload to type! " + action.payload); }
        key = payload.name;
    }

    return getLocalizedString(nodeId, key);
}

export async function setActionTitle(nodeId: number, index: number, value: string) {
    const action = actionsForNode(nodeId)[index];

    let key: string

    if (action.type === "ENUM") {
        const payload = action.payload as EnumAction.Payload;
        if (!payload) { throw Error("Could not cast payload to EnumAction type! " + action.payload); }
        key = payload.text;
    } else if (action.type === "PRIMARY") {
        const payload = action.payload as PrimaryAction.Payload;
        if (!payload) { throw Error("Could not cast payload to PrimaryAction type! " + action.payload); }
        key = payload.text;
    } else if (action.type === "SECONDARY") {
        const payload = action.payload as SecondaryAction.Payload;
        if (!payload) { throw Error("Could not cast payload to SecondaryAction type! " + action.payload); }
        key = payload.text;
    } else if (action.type === "FREE_RESPONSE") {
        const payload = action.payload as FreeResponseAction.Payload;
        if (!payload) { throw Error("Could not cast payload to FreeResponse type! " + action.payload); }
        key = payload.submitText;
    } else if (action.type === "MULTI_SELECT") {
        const payload = action.payload as MultiselectAction.Payload;
        if (!payload) { throw Error("Could not cast payload to Multiselect type! " + action.payload); }
        key = payload.submitText;
    } else if (action.type === "RULESET") {
        const payload = action.payload as RuleSetAction.Payload;
        if (!payload) { throw Error("Could not cast payload to RuleSet type! " + action.payload); }
        key = payload.name;
    } else {
        const payload = action.payload as ActionPayload;
        if (!payload) { throw Error("Could not cast payload to type! " + action.payload); }
        key = payload.name;
    }

    await setLocalizedString(nodeId, key, value);
}

export function getLocalizedString(
    nodeId: number,
    key: string,
    locale: string = "en-US"
): string {
    const node = nodeFor(nodeId); if (!node) throw new Error(`Could not find a Node with id '${nodeId}'`);
    const data = node.data as BotInputNodeData; if (!data) throw new Error(`Could not cast data to BotInput: ${node}`);
    if (Object.keys(data.payload.localizations).includes(key)) {
        return data.payload.localizations[key][locale];
    } else {
        return key;
    }
}

export async function setLocalizedString(
    nodeId: number,
    key: string,
    value: string,
    locale: string = "en-US"
) {
    const node = nodeFor(nodeId); if (!node) throw new Error(`Could not find a Node with id '${nodeId}'`);
    const data = node.data as BotInputNodeData; if (!data) throw new Error(`Could not cast data to BotInput: ${node}`);
    data.payload.localizations[key][locale] = value;
    node.data = data;
    await node.update();
}

