import axios from "axios";
import { emptyFlow } from "../Defaults";
import {Flow} from "../models/Flow";

export var store: Flow[] = [];
const baseUrl: string = "https://9w7hv6n42f.execute-api.us-west-2.amazonaws.com/prod/"

export function getStoredFlow(id: string): Flow | null {
    let storedFlow = store.find(flow => flow.id === id);
    return (storedFlow) ? storedFlow : null;
}

export async function refreshStore() {
    store = await getFlowItems();
}

export async function getFlowItems(): Promise<Flow[]> {
    const response = await axios.get(baseUrl);
    const ids: string[] = response.data.flows;
    const results = (ids.map(async id => { return await getFlow(id); }));
    return Promise.all(results).then(items => {
        return items.sort((a, b) => (a < b) ? 1 : -1);
    });
}

export async function getFlow(key: string): Promise<Flow> {
    const response = await axios.get(`${baseUrl}/${key}`);
    return response.data;
}

export async function postFlow(id: string, flowData?: Flow): Promise<Flow> {
    if (id === undefined || id === "undefined") {
        return getStoredFlow(id) || flowData || emptyFlow(id);
    }
    
    const flow: Flow = (flowData || emptyFlow(id));
    flow.updated = Math.round(Date.now() / 1000);
    
    await axios.post(`${baseUrl}/${id}`, JSON.stringify(flow));
    await refreshStore();
    return getStoredFlow(id) || flowData || emptyFlow(id);
}
