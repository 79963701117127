import React from "react";
import styled from "styled-components";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";

// eslint-disable-next-line @typescript-eslint/no-namespace
declare module Console {
    export type Props = {
        isVisible: boolean;
        code: string;
    };
    
    export type State = {
        isVisible: boolean;
        code: string;
    };
}

const Styled = {
    CodeEditor: styled.div`
    align-self: stretch;
    border-color: none;
    border-style: none;
    border-width: 0;
    color: white;
    display: flex;
    flex-direction: column-reverse;
    flex-flow: column-reverse;
    flex-grow: 1;
    font-family: Fira code, Fira Mono, monospace;
    font-size: 12;
    height: auto;
    overflow-y: scroll;
    width: 100vw;
    -ms-overflow-style: none;
    -webkit-box-flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    ::-webkit-scrollbar {
        display: none;
    }
    input:focus, 
    textarea:focus, 
    select:focus {
        outline: none;
    }
  `,
};

export class CodeEditorView extends React.Component<Console.Props, Console.State> {
    mounted: boolean = false;
    state = {
        isVisible: true,
        code: "",
    };
    
    constructor(props: Console.Props) {
        super(props);
        this.state = {
            isVisible: props.isVisible,
            code: props.code,
        };
        this.setValue = this.setValue.bind(this);
    }
    
    componentDidMount() {
        this.mounted = true;
    }
    
    setValue(event: any) {}
    
    setCode(value: string) {
        this.setState({ code: value });
    }
    
    setVisibility(isOn: boolean) {
        this.setState({ isVisible: isOn });
    }
    
    render() {
        return (
            <Styled.CodeEditor>
                <Editor
                    value={this.state.code}
                    onValueChange={(code: string) => this.setState({ code })}
                    highlight={(code: string) => highlight(code, languages.js)}
                    padding={0}
                    insertSpaces={false}
                    tabSize={1}
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12,
                        overflowY: "scroll",
                        transition: "none"
                    }}
                />
            </Styled.CodeEditor>
        );
    }
}
