import Rete from "rete";
import {NodeData, WorkerInputs, WorkerOutputs} from "rete/types/core/data";
import {CustomModalNodeData, FlowNode} from "../models/Node";
import {Action, ActionPayload, CloseModalNavigationAction, CustomAction} from "../models/Action";
import {actionSocket} from "../Constants";

export class CustomModalNodeComponent extends Rete.Component {
    static type: string = "CustomModal";

    constructor() {
        super(CustomModalNodeComponent.type);
    }

    builder(node: any) {
        const flowNode = node as FlowNode;
        const nodeData = flowNode.data as CustomModalNodeData;
        const actions: Action[] = nodeData.payload.actions || [];

        actions.forEach(action => {
            let payload: ActionPayload;

            if (action.type === "CLOSE_MODAL_NAVIGATION") {
                payload = action.payload as CloseModalNavigationAction.Payload;
                if (!payload) {
                    throw new Error("Could not cast to CloseModalNavigationAction.Payload: " + action.payload);
                }
            } else if (action.type === "CUSTOM") {
                payload = action.payload as CustomAction.Payload;
                if (!payload) {
                    throw new Error("Could not cast to CustomAction.Payload: " + action.payload);
                }
            } else {
                throw new Error("Expected CloseModalNavigationAction or CustomAction in custom modal node! Found " + action.type);
            }

            node.addOutput(
                new Rete.Output(action.id, payload.name, actionSocket, false)
            );
        })

        node.addInput(new Rete.Input("act", "Link", actionSocket, true));
        return node;
    }

    async worker(
        node: NodeData,
        inputs: WorkerInputs,
        outputs: WorkerOutputs,
        nextMessage: unknown,
        onNode = () => {}
    ) {
        // console.log('[CustomModalNode][Worker] ' + JSON.stringify(node, null, "\t") + '\n\n' + JSON.stringify(nextMessage, null, "\t"))
    }
}
