export function isElectron(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    return (userAgent.indexOf(' electron/') > -1);
}

export async function inputPrompt(
    message: string = 'Please enter a value',
    defaultValue: string = '',
    title: string = 'FlowWriter',
    type: string = 'text'
): Promise<string | null> {
    if (isElectron()) {
        const prompt = window.require("electron-prompt");
        
        return prompt({
            title: title,
            label: message,
            value: defaultValue,
            inputAttrs: { type: type },
            type: 'input'
        })
        .then((result: string) => {
            return result;
        })
        .catch((error: Error) => {
            console.log('InputPromptError: ' + error);
            return null;
        });
    } else {
        return prompt(message, defaultValue) || null
    }
}
