import {InputsData, NodeData, OutputsData} from "rete/types/core/data";
import {Action, CloseModalNavigationAction, ResultAction, RuleSetAction} from "./Action";
import {Localizations} from "./Localization";

export type FlowNodeType =
    | "BotInput"
    | "CustomModal"
    | "WebModal"
    | "Network"
    | "Router"
    | "Result"

export class FlowNode implements NodeData {
    id: number;
    data: FlowNodeData;
    inputs: InputsData;
    outputs: OutputsData;
    position: [number, number];
    name: FlowNodeType;

    constructor(
        id: number,
        data: FlowNodeData,
        inputs: InputsData,
        outputs: OutputsData,
        position: [number, number],
        name: FlowNodeType
    ) {
        this.id = id;
        this.data = data;
        this.inputs = inputs;
        this.outputs = outputs;
        this.position = position;
        this.name = name;
    }
}

export type NodeDataData = {
    [key: string]: unknown;
}

export interface FlowNodeData extends NodeDataData {
    label: string;
    type: FlowNodeType;
    payload: FlowNodeDataPayload;
}

export interface DisplayableFlowNodeData extends FlowNodeData {}

export interface ModalNodeData extends DisplayableFlowNodeData {}

export interface FlowNodeDataPayload {
    actions: Action[];
}

export class BotInputNodeData implements DisplayableFlowNodeData {
    label: string;
    payload: BotInputNodeDataPayload;
    type: FlowNodeType;

    [key: string]: unknown;

    constructor(
        label: string,
        payload: BotInputNodeDataPayload,
        type: FlowNodeType
    ) {
        this.label = label;
        this.payload = payload;
        this.type = type;
    }
}

export class BotInputNodeDataPayload implements FlowNodeDataPayload {
    actions: Action[];
    presentationSpeed: PresentationSpeed;
    localizations: Localizations;
    body: string[];

    constructor(
        actions: Action[],
        presentationSpeed: PresentationSpeed,
        localizations: Localizations,
        body: string[]
    ) {
        this.actions = actions;
        this.presentationSpeed = presentationSpeed;
        this.localizations = localizations;
        this.body = body;
    }
}

export type PresentationSpeed =
    | "SLOW"
    | "NORMAL"
    | "FAST"
    | "INSTANT"

export class CustomModalNodeData implements ModalNodeData {
    label: string;
    payload: CustomModalNodeDataPayload;
    type: FlowNodeType;

    [key: string]: unknown;

    constructor(
        label: string,
        payload: CustomModalNodeDataPayload,
        type: FlowNodeType
    ) {
        this.label = label;
        this.payload = payload;
        this.type = type;
    }
}

export type Type =
    | "SSN_EXPLANATION"
    | "UPDATE_PAYMENT"

export class CustomModalNodeDataPayload implements FlowNodeDataPayload {
    actions: Action[];
    type: Type;

    constructor(
        actions: Action[],
        type: Type
    ) {
        this.actions = actions;
        this.type = type;
    }
}

export class WebModalNodeData implements ModalNodeData {
    label: string;
    payload: WebModalNodeDataPayload;
    type: FlowNodeType;

    [key: string]: unknown;

    constructor(
        label: string,
        payload: WebModalNodeDataPayload,
        type: FlowNodeType
    ) {
        this.label = label;
        this.payload = payload;
        this.type = type;
    }
}

export class WebModalNodeDataPayload implements FlowNodeDataPayload {
    actions: CloseModalNavigationAction[];
    url: string;

    constructor(
        actions: CloseModalNavigationAction[],
        url: string
    ) {
        this.actions = actions;
        this.url = url;
    }
}

export class NetworkNodeData implements FlowNodeData {
    label: string;
    payload: NetworkNodeDataPayload;
    type: FlowNodeType;

    [key: string]: unknown;

    constructor(
        label: string,
        payload: NetworkNodeDataPayload,
        type: FlowNodeType
    ) {
        this.label = label;
        this.payload = payload;
        this.type = type;
    }
}

export class NetworkNodeDataPayload implements FlowNodeDataPayload {
    actions: Action[];
    localizations: Localizations;

    constructor(
        actions: Action[],
        localizations: Localizations
    ) {
        this.actions = actions;
        this.localizations = localizations;
    }
}

export class ResultNodeData implements FlowNodeData {
    label: string;
    payload: ResultNodeDataPayload;
    type: FlowNodeType;

    [key: string]: unknown;

    constructor(
        label: string,
        payload: ResultNodeDataPayload,
        type: FlowNodeType
    ) {
        this.label = label;
        this.payload = payload;
        this.type = type;
    }
}

export class ResultNodeDataPayload implements FlowNodeDataPayload {
    actions: ResultAction[];

    constructor(actions: ResultAction[]) {
        this.actions = actions;
    }
}

export class RouterNodeData implements FlowNodeData {
    label: string;
    payload: RouterNodeDataPayload;
    type: FlowNodeType;

    [key: string]: unknown;

    constructor(
        label: string,
        payload: RouterNodeDataPayload,
        type: FlowNodeType
    ) {
        this.label = label;
        this.payload = payload;
        this.type = type;
    }
}

export class RouterNodeDataPayload implements FlowNodeDataPayload {
    actions: RuleSetAction[];

    constructor(actions: RuleSetAction[]) {
        this.actions = actions;
    }
}
