import React from "react";
import styled from "styled-components";
import {DEFAULT_FLOW} from "../Constants";

interface Props {
    isVisible: boolean;
    deviceColor: string;
    deviceType: Phone;
    scale: number;
    flowId: string;
    isDualMode: boolean;
}

interface State {
    isVisible: boolean;
    deviceColor: string;
    deviceType: Phone;
    scale: number;
    flowId: string;
    isDualMode: boolean;
}

export enum Phone {
    IPHONE = "iPhone",
    ANDROID = "Android"
}

interface AppetizeConfig {
    id: string;
    device: string;
    scale: number;
    width: number;
    height: number;
    orientation: string;
    osVersion: string;
}

const IPHONE_CONFIG: AppetizeConfig = {
    id: "d7mu2z4rpq2qhbftcmd1wy0dam",
    device: "iphonexs",
    scale: 65,
    width: 266,
    height: 550,
    orientation: "portrait",
    osVersion: "13.3"
};

const ANDROID_CONFIG: AppetizeConfig = {
    id: "qggd70zy1nk8b2pwhvzzj7dvjm",
    device: "pixel4",
    scale: 67,
    width: 257,
    height: 566,
    orientation: "portrait",
    osVersion: "10.0"
};

const Styled = {
    FloatingPreview: styled.div`
        position: absolute;
        left: 24px;
        bottom 24px;
        background-color: black;
        padding: 0px;
        overflow: hidden:
        border: none;
        border-radius: 44px;
        box-shadow: 6px 6px 10px rgba(34, 34, 55, 0.9);
        width: ${IPHONE_CONFIG.width}px;
        height: ${IPHONE_CONFIG.height}px;
    `,
    FloatingSimulator: styled.iframe`
        background: transparent;
        position: relative;
        width: 100%;
        height: 100%;
    `,
    Button: styled.button``,
    HR: styled.hr``,
};

export class PreviewView extends React.Component<Props, State> {
    mounted: boolean = false;
    state = {
        isVisible: false,
        deviceColor: "black",
        deviceType: Phone.IPHONE,
        scale: 65,
        flowId: DEFAULT_FLOW,
        isDualMode: false
    };
    
    constructor(props: Props) {
        super(props);
        
        this.state = {
            isVisible: props.isVisible,
            deviceColor: props.deviceColor,
            deviceType: props.deviceType,
            scale: props.scale,
            flowId: props.flowId,
            isDualMode: props.isDualMode
        };
        
        this.setValue = this.setValue.bind(this);
    }
    
    componentDidMount() {
        this.mounted = true;
    }
    
    componentWillReceiveProps(props: Props) {
        this.setState(props);
    }
    
    setValue(event: any) {}
    
    setFlow(id: string) {
        this.setState({ flowId: id });
    }
    
    generateEmbedSource(
        type: Phone = Phone.IPHONE,
        flowId: string = DEFAULT_FLOW
    ): string {
        const config: AppetizeConfig = (type === Phone.IPHONE) ? IPHONE_CONFIG : ANDROID_CONFIG;
        const appURL = `https://appetize.io/embed/${config.id}`;
        let source = `${appURL}?device=${config.device}&scale=${config.scale}&autoplay=${false}&orientation=${config.orientation}&deviceColor=${this.state.deviceColor}&osVersion=${config.osVersion}`;
        if (type === Phone.IPHONE) {
            source = source + `&launchUrl=florunner:%2F%2F${flowId}`;
        }
        return source;
    }
    
    render() {
        const source = this.generateEmbedSource(
            this.state.deviceType,
            this.state.flowId
        );
        
        return (
            <Styled.FloatingPreview
                style={{
                    left: (this.state.deviceType === Phone.ANDROID) ? "305px" : "20px",
                    width: `${(this.state.deviceType === Phone.IPHONE) ? IPHONE_CONFIG.width : ANDROID_CONFIG.width}px`,
                    height: `${(this.state.deviceType === Phone.IPHONE) ? IPHONE_CONFIG.height : ANDROID_CONFIG.height}px`,
                    borderRadius: `${(this.state.deviceType === Phone.IPHONE) ? 44 : 30}px`,
                    opacity: `${(this.state.isVisible) ? 1 : 0}`
                }}
            >
                <Styled.FloatingSimulator
                    src={source}
                    width={`${(this.state.deviceType === Phone.IPHONE) ? IPHONE_CONFIG.width : ANDROID_CONFIG.width}px`}
                    height={`${(this.state.deviceType === Phone.IPHONE) ? IPHONE_CONFIG.height : ANDROID_CONFIG.height}px`}
                    frameBorder="0"
                    scrolling="no"
                />
            </Styled.FloatingPreview>
        );
    }
}
