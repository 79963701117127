import * as Rete from "rete";
import { NodeData, WorkerInputs, WorkerOutputs } from "rete/types/core/data";
import {ResultNodeDataPayload, ResultNodeData} from "../models/Node";
import {ResultAction} from "../models/Action";
import {actionSocket} from "../Constants";

export class ResultNodeComponent extends Rete.Component {
    static type: string = "Result";
    
    constructor() {
        super(ResultNodeComponent.type);
    }
    
    builder(node: any) {
        let resultName: string

        if (Object.keys(node.data).length === 0) {
            resultName = `RESULT_${node.id}_0`
            node.data.label = resultName

            node.data = new ResultNodeData(
                `NODE_${node.id}`,
                new ResultNodeDataPayload(
                    [
                        {
                            "id": `${node.id}_0`,
                            "type": "RESULT",
                            "valueTransform": null,
                            "variableAssignments": {},
                            "payload": {
                                "name": resultName,
                                "value": null
                            } as ResultAction.Payload
                        }
                    ]
                ),
                "Result"
            );

            node.update();
        }

        const action = (((node.data as ResultNodeData).payload as ResultNodeDataPayload).actions[0] as ResultAction);
        action.payload.name = node.data.label

        node.addInput(new Rete.Input("act", "Link", actionSocket, true));

        node.addOutput(
            new Rete.Output(action.id, action.payload.name, actionSocket, false)
        );

        return node;
    }

    async worker(
        node: NodeData,
        inputs: WorkerInputs,
        outputs: WorkerOutputs,
        nextMessage: unknown,
        onNode = () => {}
    ) {
        // console.log('[ResultNode][Worker] ' + JSON.stringify(node, null, "\t") + '\n\n' + JSON.stringify(nextMessage, null, "\t"))
    }
}
