import React from "react";
import styled from "styled-components";
import { Icon } from "../views/Icon";
import { loadFlow } from "../Editor";
import { getFlowItems, postFlow } from "../store/Store";
import { inputPrompt } from "../helpers/PromptHelper";
import { startLoading, stopLoading } from "../helpers/LoaderHelper";
import {Flow} from "../models/Flow";

interface Props {
    isVisible: boolean;
    isInputVisible: boolean;
    items: Flow[];
}

interface State {
    isVisible: boolean;
    isInputVisible: boolean;
    items: Flow[];
}

// MARK: Styles

const flowPanelWidth = 300;

const Leftcard = styled.div<State>`
  cursor: pointer;
  width: ${flowPanelWidth}px;
  left: ${(props: State) =>
    props.isVisible ? `0` : `-${flowPanelWidth - 10}px`};
  background-color: #fff;
  border: 1px solid #e8e8ef;
  box-sizing: border-box;
  padding-top: 85px;
  padding-left: 20px;
  height: 100%;
  position: absolute;
  z-index: 2;
  transition: left 0.3s cubic-bezier(0.05, 0.03, 0.35, 1);
  box-shadow: ${(props: State) =>
    props.isVisible ? "-4px 0px 40px rgba(26, 26, 73, 0.05)" : "none"};
`;

const ClosecardGraphic = styled.div<State>`
  margin-left: ${(props: State) => (props.isVisible ? "-4px" : "0px")};
  margin-top: 13px;
  vertical-align: middle;
  transform: ${(props: State) => props.isVisible ? "scaleX(1)" : "scaleX(-1)"};
  
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const Styled = {
    Subnav: styled.div`
    border-bottom: 1px solid #e8e8ef;
    width: calc(100% - 20px);
    margin-left: -20px;
    margin-top: 10px;
  `,
    NavDisabled: styled.div`
    transition: all 0.3s cubic-bezier(0.05, 0.03, 0.35, 1);
    :hover {
      cursor: pointer;
      opacity: 0.5;
    }
  `,
    NavActive: styled.div`
    color: #393c44 !important;
  `,
    Flows: styled.div`
    margin-left: 20px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #808292;
    width: calc(88% / 3);
    height: 48px;
    line-height: 48px;
    display: block;
    float: left;
  `,
    Header: styled.p`
    font-size: 20px;
    font-family: Roboto;
    font-weight: bold;
    color: #393c44;
    margin-bottom: 0;
  `,
    AddIcon: styled(Icon)`
    display: inline;
    float: right;
    margin-right: 0;
    margin-left: 10px;
    padding: 0;
  `,
    Search: styled.div`
    input {
      width: ${flowPanelWidth}px;
      height: 40px;
      background-color: #fff;
      border: 1px solid #e8e8ef;
      box-sizing: border-box;
      box-shadow: 0px 2px 8px rgba(34, 34, 87, 0.05);
      border-radius: 5px;
      text-indent: 35px;
      font-family: Roboto;
      font-size: 16px;
    }
    Icon {
      position: absolute;
      margin-top: 10px;
      width: 18px;
      margin-left: 12px;
    }
  `,
    Row: styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-left: 0;
    padding: 0;
    Icon {
      margin-top: 17px;
      float: right;
      margin-right: 15px;
    }
  `,
    AddRow: styled.div`
    opacity: 1.0;
    vertical-align: middle;
    margin-left: 0;
    padding: 0;
    display: inline;
    position: absolute;
    right: 6px;
    margin-top: -28px;
    margin-right: 6px;
    float: right;
    height: 40px;
    width: 40px;
    overflow: hidden;
    
    Icon {
      margin-top: 17px;
      float: right;
      margin-right: 15px;
    }
    
    :hover {
        opacity: 0.6;
    }
  `,
    Closecard: styled.div`
    position: absolute;
    margin-left: ${flowPanelWidth - 23}px;
    background-color: #fff;
    border-radius: 0px 5px 5px 0px;
    border-bottom: 1px solid #e8e8ef;
    border-right: 1px solid #e8e8ef;
    border-top: 1px solid #e8e8ef;
    width: 53px;
    height: 53px;
    text-align: center;
    z-index: 10;
  `,
    Blocklist: styled.div`
    height: 100%;
    overflow: auto;
    margin-left: -20px;
    padding-left: 6px;
    margin-top: 0;
    padding-top: 0;
  `,
};

// MARK: Component

export class FlowsPanel extends React.Component<Props, State> {
    mounted: boolean = false;
    timer?: number;
    
    constructor(props: Props) {
        super(props);
        
        this.state = {
            isVisible: props.isVisible,
            isInputVisible: props.isInputVisible,
            items: props.items,
        };
    }
    
    updateFlows(repeat: boolean = true) {
        if (this.timer) clearTimeout(this.timer);
        
        getFlowItems().then(items => {
            this.setFlows(items);
            if (repeat) {
                this.timer = setTimeout(this.updateFlows(true), 10000);
            }
        });
    }
    
    componentDidMount() {
        this.mounted = true;
        this.updateFlows(false);
    }
    
    setVisibility(isVisible: boolean) {
        if (isVisible) {
            this.updateFlows(true);
        } else {
            if (this.timer) clearTimeout(this.timer);
        }
        
        this.setState({ isVisible });
    }
    
    setFlows(items: Flow[]) {
        this.setState({ items });
    }
    
    async createNewFlow() {
        const newFlowName = await inputPrompt("Enter a descriptive name for your new Flow (min 3 chars)", "");

        if (!newFlowName || newFlowName === null || newFlowName.length < 2) {
            console.log('Error creating new Flow!'); return;
        }
        
        let idSuggestion: string = newFlowName.toUpperCase().trim()
            .replace(" ", "_")
            .replace(".", "_")
            .replace("-", "_")
            .replace(":", "_")
            .replace(",", "_")
            .replace("(", "_")
            .replace(")", "");

        idSuggestion = idSuggestion.substr(0, Math.min(idSuggestion.length, 14));
        const newFlowId = idSuggestion;
        
        if (newFlowId && newFlowId.length > 2) {
            postFlow(newFlowId, undefined).then((newFlow: Flow) => {
                if (newFlow) {
                    loadFlow(newFlowId).then(() => {
                        this.setVisibility(false);
                    });
                } else {
                    console.log('Error creating new flow: ' + newFlowId);
                    return;
                }
            })
        }
    }
    
    render() {
        return (
            <Leftcard isVisible={this.state.isVisible} isInputVisible={this.state.isInputVisible}
                      items={this.state.items}>
                <Styled.Closecard
                    onClick={() => {
                        this.setVisibility(!this.state.isVisible);
                    }}
                >
                    <ClosecardGraphic
                        isVisible={this.state.isVisible}
                        isInputVisible={this.state.isInputVisible}
                        items={this.state.items}
                    >
                        <Icon graphic="CLOSE_LEFT"/>
                    </ClosecardGraphic>
                </Styled.Closecard>
                <Styled.Row>
                    <Styled.Header key="flows-header-title">Flows</Styled.Header>
                    <Styled.AddRow onClick={async () => {
                        startLoading();
                        await this.createNewFlow();
                        stopLoading(100);
                    }}>
                        <Styled.AddIcon
                            graphic="NEW"
                            key="create-new-flow-icon"
                        />
                    </Styled.AddRow>
                </Styled.Row>
                <Styled.Blocklist key="flow-list">
                    {this.state.items.map((item) => {
                        return (
                            <div
                                className="blockelem create-flowy noselect"
                                key={"block-" + item.id}
                            >
                                <input
                                    key={"block-input-" + item.id}
                                    type="hidden"
                                    name="blockelemtype"
                                    className="blockelemtype"
                                    value="1"
                                />
                                <div className="grabme" key={"block-graphic-" + item.id}>
                                    <Icon graphic="GRAB"/>
                                </div>
                                <div
                                    key={"block-list-item-" + item.id}
                                    className="blockin"
                                    onClick={() => {
                                        startLoading();
                                        loadFlow(`${item.id}`).then(() => {
                                            this.setVisibility(false);
                                            stopLoading(250);
                                        });
                                    }}
                                >
                                    <div
                                        className="blocktext"
                                        key={"block-list-item-text-" + item.id}
                                    >
                                        <p className="blocktitle">{item.id}</p>
                                        <p className="blockdesc">{item.id}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Styled.Blocklist>
            </Leftcard>
        );
    }
}
