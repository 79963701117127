import React, { ChangeEvent } from "react";
import { Icon } from "../views/Icon";
import {editor, initializeFlow, publishModel, saveCurrentFlow} from "../Editor";
import {iphone, android, userName, setUser, iphoneView, androidView} from "../App";
import styled from "styled-components";

export enum EditorMode {
    ANDROID = "Android",
    IPHONE = "iPhone",
    BOTH = "Both",
    NONE = "None"
}

interface Props {
    mode: EditorMode;
    name: string;
    description: number;
}

interface State {
    mode: EditorMode;
    name: string;
    description: number;
}

const Styled = {
    Container: styled.div`
    height: 71px;
    background-color: #fff;
    border: 1px solid #e8e8ef;
    width: 100%;
    display: table;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    z-index: 9;
  `,
    Back: styled.div`
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background-color: #f1f4fc;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    margin-top: 15px;
    margin-right: 10px;
    img {
      margin-top: 13px;
    }
    
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }

    @media only screen and (max-width: 600px) {
      display: none;
    }
  `,
    Names: styled.div`
    display: inline-block;
    vertical-align: top;
    @media only screen and (max-width: 600px) {
      display: none;
    }

    @media only screen and (max-width: 560px) {
      display: inline-block;
    }
  `,
    Title: styled.p`
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    color: #393c44;
    margin-bottom: 0px;
  `,
    Subtitle: styled.p`
    font-family: Roboto;
    color: #808292;
    font-size: 14px;
    margin-top: 5px;
  `,
    Leftside: styled.div`
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
  `,
    SwitchContainer: styled.div`
        position: absolute;
        width: 360px;
        left: 50%;
        margin-left: -111px;
        top: 15px;
        @media only screen and (max-width: 600px) {
          margin-left: 0;
          margin-right: 15px;
          right: 50%;
        }
        @media only screen and (max-width: 450px) {
          display: none;
        }
  `,
    ButtonsRight: styled.div`
        float: right;
        margin-left: 10px;
        margin-top: 15px;
        @media only screen and (max-width: 620px) {
          display: none;
        }
  `,
    FileInputLabel: styled.label`
    width: 72px;
    height: 38px;
    display: inline-block;
    cursor: pointer;
  `,
    HiddenFileInput: styled.input`
    display: inline-block;
    vertical-align: top;
    opacity: 0;
    margin-top: -38px;
    width: 80px;
    height: 38px;
    line-height: 38px;
    cursor: pointer;
  `,
    LoadButton: styled.div`
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    background-color: #217ce8;
    width: 70px;
    height: 38px;
    border: 1px solid #e8e8ef;
    border-radius: 5px;
    text-align: center;
    line-height: 38px;
    display: inline-block;
    vertical-align: top;
    transition: all 0.2s cubic-bezier(0.05, 0.03, 0.35, 1);
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
  `,
    PublishButton: styled.div`
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    background-color: #217ce8;
    border-radius: 5px;
    width: 90px;
    height: 38px;
    margin-left: 5px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    line-height: 38px;
    margin-right: 6px;
    transition: all 0.2s cubic-bezier(0.05, 0.03, 0.35, 1);
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
  `,
    SaveButton: styled.div`
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    background-color: #217ce8;
    border-radius: 5px;
    width: 70px;
    height: 38px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    line-height: 38px;
    margin-left: 0px;
    margin-right: 16px;
    transition: all 0.2s cubic-bezier(0.05, 0.03, 0.35, 1);
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
  `,
    LeftSwitch: styled.div`
    background-color: "#eeeeee";
    border: 1px solid #e8e8ef;
    border-radius: 5px 0px 0px 5px;
    color: "#393c44";
    display: inline-block;
    font-family: Roboto;
    font-size: 14px;
    height: 39px;
    line-height: 39px;
    text-align: center;
    width: 75px;
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
  `,
    MiddleSwitch: styled.div`
    background-color: "#ffffff";
    border: 1px solid #e8e8ef;
    border-radius: 0px 0px 0px 0px;
    color: "#808292";
    display: inline-block;
    font-family: Roboto;
    font-size: 14px;
    height: 39px;
    line-height: 39px;
    text-align: center;
    width: 75px;
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
  `,
    RightSwitch: styled.div`
    background-color: "#ffffff";
    border: 1px solid #e8e8ef;
    border-radius: 0px 5px 5px 0px;
    color: "#808292";
    display: inline-block;
    font-family: Roboto;
    font-size: 14px;
    height: 39px;
    line-height: 39px;
    text-align: center;
    width: 75px;
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
  `,
    Component: styled.div``,
};

export class HeaderView extends React.Component<Props, State> {
    mounted: boolean = false;
    iphoneSwitch: HTMLDivElement | undefined;
    androidSwitch: HTMLDivElement | undefined;
    
    state = {
        mode: EditorMode.NONE,
        name: "",
        description: 0,
    };
    
    constructor(props: Props) {
        super(props);
        
        this.state = {
            mode: props.mode,
            name: props.name,
            description: props.description,
        };
        
        this.setValue = this.setValue.bind(this);
    }

    timestampToDateString(timestamp: number): string {
        const timestampMillis = Number(`${timestamp}000`);
        const date = new Date(timestampMillis);
        return `Last saved: ${date.toLocaleDateString("en-US")} ${date.toLocaleTimeString("en-US")}`;
    }

    setValue(event: any) {}
    
    componentDidMount() {
        this.mounted = true;
        this.setState({mode: EditorMode.NONE});
    }

    setName(value: string) {
        this.setState({ name: value });
    }
    
    setDescription(value: number) {
        this.setState({ description: value });
    }
    
    setSwitches(mode: EditorMode) {
        const { iphoneSwitch, androidSwitch } = this;
        
        if (!iphoneSwitch || !androidSwitch) return;
        
        switch (mode) {
            case EditorMode.ANDROID:
                iphoneSwitch.style.backgroundColor = "#ffffff";
                iphoneSwitch.style.color = "#393c44";
                androidSwitch.style.backgroundColor = "#eeeeee";
                androidSwitch.style.color = "#808292";
                break;
            case EditorMode.BOTH:
                iphoneSwitch.style.backgroundColor = "#eeeeee";
                iphoneSwitch.style.color = "#808292";
                androidSwitch.style.backgroundColor = "#eeeeee";
                androidSwitch.style.color = "#808292";
                break;
            case EditorMode.IPHONE:
                iphoneSwitch.style.backgroundColor = "#eeeeee";
                iphoneSwitch.style.color = "#808292";
                androidSwitch.style.backgroundColor = "#ffffff";
                androidSwitch.style.color = "#393c44";
                break;
            case EditorMode.NONE:
                iphoneSwitch.style.backgroundColor = "#ffffff";
                iphoneSwitch.style.color = "#393c44";
                androidSwitch.style.backgroundColor = "#ffffff";
                androidSwitch.style.color = "#393c44";
                break;
            default:
                break;
        }
        
        this.toggleVisibility(mode);
    }
    
    setMode(option: EditorMode) {
        this.setSwitches(option);
        this.setState({ mode: option });
    }
    
    toggleVisibility(mode: EditorMode) {
        if (!iphone || !android) return;
        
        switch (mode) {
            case EditorMode.ANDROID:
                iphoneView.setState({isVisible: false});
                androidView.setState({isVisible: true});

                iphone.style.opacity = "0";
                android.style.opacity = "1";
                break;
            case EditorMode.BOTH:
                iphoneView.setState({isVisible: true});
                androidView.setState({isVisible: true});

                android.style.opacity = "1";
                iphone.style.opacity = "1";
                break;
            case EditorMode.IPHONE:
                iphoneView.setState({isVisible: true});
                androidView.setState({isVisible: false});

                android.style.opacity = "0";
                iphone.style.opacity = "1";
                break;
            case EditorMode.NONE:
                iphoneView.setState({isVisible: false});
                androidView.setState({isVisible: false});

                android.style.opacity = "0";
                iphone.style.opacity = "0";
                break;
            default:
                break;
        }
    }
    
    render() {
        return (
            <>
                <Styled.Container>
                    <Styled.Leftside>
                        <Styled.Component>
                            <Styled.Back
                                onClick={async () => {
                                    if (userName !== "") {
                                        setUser("");
                                        await initializeFlow();
                                    };
                                }}
                            >
                                <Icon graphic="BACK-ARROW"/>
                            </Styled.Back>
                            <Styled.Names>
                                <Styled.Title>{this.state.name}</Styled.Title>
                                <Styled.Subtitle>{this.timestampToDateString(this.state.description)}</Styled.Subtitle>
                            </Styled.Names>
                        </Styled.Component>
                    </Styled.Leftside>
                    <Styled.SwitchContainer>
                        <Styled.LeftSwitch
                            ref={(el: HTMLDivElement) => (this.iphoneSwitch = el)}
                            onClick={() => {
                                switch (this.state.mode) {
                                    case EditorMode.IPHONE:
                                        this.setMode(EditorMode.NONE);
                                        break;
                                    case EditorMode.BOTH:
                                        this.setMode(EditorMode.ANDROID);
                                        break;
                                    case EditorMode.ANDROID:
                                        this.setMode(EditorMode.BOTH);
                                        break;
                                    case EditorMode.NONE:
                                        this.setMode(EditorMode.IPHONE);
                                        break;
                                    default:
                                        break;
                                }
                            }}
                        >
                            iPhone
                        </Styled.LeftSwitch>
                        <Styled.RightSwitch
                            ref={(el: HTMLDivElement) => (this.androidSwitch = el)}
                            onClick={() => {
                                switch (this.state.mode) {
                                    case EditorMode.NONE:
                                        this.setMode(EditorMode.ANDROID);
                                        break;
                                    case EditorMode.IPHONE:
                                        this.setMode(EditorMode.BOTH);
                                        break;
                                    case EditorMode.ANDROID:
                                        this.setMode(EditorMode.NONE);
                                        break;
                                    case EditorMode.BOTH:
                                        this.setMode(EditorMode.IPHONE);
                                        break;
                                    default:
                                        break;
                                }
                            }}
                        >
                            Android
                        </Styled.RightSwitch>
                    </Styled.SwitchContainer>
                    <Styled.ButtonsRight>
                        <Styled.LoadButton>
                            <Styled.FileInputLabel>Load</Styled.FileInputLabel>
                            <Styled.HiddenFileInput
                                type="file"
                                id="files"
                                name="files[]"
                                multiple
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    if (!event.target.files) return;
                                    let file = event.target.files[0];
                                    
                                    if (!file) {
                                        alert("Failed to load file!");
                                        return;
                                    }
                                    
                                    const read = new FileReader();
                                    read.onload = function (e) {
                                        const result = e.target?.result as string;
                                        if (!result) return;
                                        const json = JSON.parse(result);
                                        editor.fromJSON(json).then();
                                    };
                                    read.readAsText(file);
                                }}
                            />
                        </Styled.LoadButton>
                        <Styled.PublishButton
                            onClick={async () => {
                                await publishModel()
                            }}
                        >
                            Export
                        </Styled.PublishButton>
                        <Styled.SaveButton
                            onClick={async () => {
                                await saveCurrentFlow()
                            }}
                        >
                            Save
                        </Styled.SaveButton>
                    </Styled.ButtonsRight>
                </Styled.Container>
            </>
        );
    }
}
